import * as React from 'react';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import IconUpload from '@mui/icons-material/FileUpload';
import { Button} from 'react-admin';
import { confirmAlert } from 'react-confirm-alert';

class PushBulkButton extends Component {
  state = {
    jobInterval: 0
  };
  fetchJob = async (jobId) => {
      const token = localStorage.getItem('tap-token');
      const requestOptions = {
         method: 'GET',
         headers: { 'Content-Type': 'application/json', Authorization: token},
      };
      console.log("jobId " + jobId)
      return await fetch(process.env.REACT_APP_API_URL + '/jobs/' + jobId, requestOptions)
      .then(response => response.json())
      .then(job => {
        console.log("job ", job)
        if (job.httpCode !== 500 && job.jobStatus !== 'RUNNING' && job.jobStatus !== 'CREATED') {
          clearInterval(this.state.jobInterval);
          var successMessage = <div><div className="toastTitle">Success</div><div className="toastMainMessage">Selected offers pushed/updated to RL, Please Wait...</div></div>
          toast.success(successMessage, {
            position: toast.POSITION.BOTTOM_CENTER,
            closeButton: true,
            autoClose: 2000,
            onClose: () => window.location.reload(true)
          });
        } else if (job.httpCode === 500 || job.jobStatus === 'FAILED') {
          clearInterval(this.state.jobInterval);
          var errorMessage = <div><div className="toastTitle">Error</div><div className="toastMainMessage">Error pushing/updating offers to RL</div></div>
          toast.error(errorMessage, {
            position: toast.POSITION.BOTTOM_CENTER,
            closeButton: true,
            autoClose: false
          });
        }
      }).catch(({ status, statusText}) => {
        console.log("Caught status status ", status);
        console.log("Caught status statusText ", statusText);
        return []
      });
  };
  handleClick = () => {
    confirmAlert({
      title: 'Push/Update Selected Offers to RL',
      message: 'Are you sure you want to push/update all selected offers to RightsLine?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props.isLoading(true)
            var selectedRows = this.props.selectedrows.data
            var dataTable = this.props.dataTable
            var selectedRowIdPayload = []
            selectedRows.forEach(element => {
              selectedRowIdPayload.push(dataTable[element.dataIndex].id)
            });
            const token = localStorage.getItem('tap-token');
            const requestOptions = {
               method: 'POST',
               headers: { 'Content-Type': 'application/json', Authorization: token},
               body: JSON.stringify(selectedRowIdPayload)
            };
            fetch(process.env.REACT_APP_API_URL +  '/licensing/pushUpdateOfferBulk', requestOptions).then(response => response.json())
            .then((responsData) => {
              console.log("pushUpdateOfferBulk responsData ", responsData)
              if (responsData && typeof responsData !== 'object') {
                var self = this
                var jobInterval = setInterval(function() {self.fetchJob(responsData)}, 5000);
                this.setState({ jobInterval: jobInterval });
              } else {
                this.props.isLoading(false)
                var errorMessage = <div><div className="toastTitle">Error</div><div className="toastMainMessage">{responsData.statusText}</div></div>
                toast.error(errorMessage, {
                  position: toast.POSITION.BOTTOM_CENTER,
                  closeButton: true,
                  autoClose: false
                });
              }
            }).catch(({ status, statusText, headers, body }) => {
                console.log("Caught status status ", status);
                console.log("Caught status statusText ", statusText);
                console.log("Caught status body ", body);
            });
          }
        },
        {
          label: 'No',
          onClick: () => console.log("User cancelled push to RL")
        }
      ]
    });
  }
      render() {
        return (
            <Fragment>
            <div id="pushToRLbulk" style={{ float: 'right', marginLeft: 'auto', marginRight: '20px' }} >
              <Button color="primary" style={{ float: 'center', marginLeft: 'center' }} label="Push/Update to RL" onClick={this.handleClick} ><IconUpload/></Button>
            </div>
            </Fragment>
            )
      }
}

const PushOffer = connect()(PushBulkButton);
export default PushOffer

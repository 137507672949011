import * as React from 'react';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { FileCopy } from '@material-ui/icons';
import { Button} from 'react-admin';
import { confirmAlert } from 'react-confirm-alert';

class CopyButton extends Component {
  handleClick = () => {
    confirmAlert({
      title: 'Copy Job',
      message: 'Are you sure you wish to copy the licensing job?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            var jobId = this.props.id
            const token = localStorage.getItem('tap-token');
            const requestOptions = {
               method: 'POST',
               headers: { 'Content-Type': 'application/json', Authorization: token},
               body: [jobId]
            };
            fetch(process.env.REACT_APP_API_URL +  '/licensing/copy', requestOptions).then(response => response.json())
            .then(body => {
              if(body === true){
                var successMessage = <div><div className="toastTitle">Success</div><div className="toastMainMessage">"Success, Job was copied"</div></div>
                toast.success(successMessage, {
                  position: toast.POSITION.BOTTOM_CENTER,
                  closeButton: true,
                  autoClose: 5000
                });
                window.history.go();
              }else{
                var errorMessage = <div><div className="toastTitle">Error</div><div className="toastMainMessage">"Error"</div></div>
                toast.error(errorMessage, {
                  position: toast.POSITION.BOTTOM_CENTER,
                  closeButton: true,
                  autoClose: 5000
                });
              }
            }).catch(({ status, statusText}) => {
                console.log("Caught status status ", status);
                console.log("Caught status statusText ", statusText);
            })
          }
        },
        {
          label: 'No',
          onClick: () => console.log("User cancelled licensing job change")
        }
      ]
    });
  }
      render() {
        return (
            <Fragment>
            <div id="runJobButton" style={{ float: 'right', marginLeft: 'auto' }} >
              <Button color="primary" style={{ float: 'center', marginLeft: 'center' }} label="Run Licensing Job" onClick={this.handleClick} {...this.props} ><FileCopy/></Button>
            </div>
            </Fragment>
            )
      }
}

const CopyJob= connect()(CopyButton);
export default CopyJob

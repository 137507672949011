import React, { Component, Fragment }  from 'react';
import { List, Datagrid, TextField, ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';
import { TopToolbar, RefreshButton, TextInput, NumberField } from 'react-admin';
import { Filter, ExportButton, Pagination, usePermissions } from 'react-admin';
import JobView from '../../components/JobStatusPanel'
import Moment from 'moment-timezone';
import * as Config from '../../Config'

const UpdateEngineActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
    permissions
}) => (
    <TopToolbar>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }        
        <ShowRunUpdateEngineButtonAdmin/>        
        <span style={{ width: '15px' }}>&nbsp;</span>
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
        <span style={{ width: '15px' }}>&nbsp;</span>
        <RefreshButton />
        {/* Add your custom actions
          <Button color="primary" onClick={customAction}>Custom Action</Button>
        */}
    </TopToolbar>
);

const ShowRunUpdateEngineButtonAdmin = props => {
  const { permissions } = usePermissions();
  return <Fragment>
          { permissions && permissions?.split(',').includes(Config.ROLE_TAP_ADMIN) && <RunUpdateEngineButton {...props}/> }
  </Fragment>
};

export const UpdateEngineList = props => (
  <UpdateEngineListSection {...props} />
);
/*
<ReferenceField sortable={false} label="Job Type" reference="jobs/jobTypes" source="spaJobType">
    <SingleFieldList >
        <ChipField source="name" />
    </SingleFieldList>
</ReferenceField>
*/
const UpdateEngineFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Job IDs" source="jobIds" alwaysOn />
        <ReferenceArrayInput allowEmpty={false} label="Job Status" reference="jobs/jobStatuses" source="spaJobStatuses" alwaysOn>
          <AutocompleteArrayInput optionText="name" resettable  />
        </ReferenceArrayInput>
    </Filter>
);

const UpdateEnginePagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500]} {...props} />

const RunUpdateEngineButton = ({ record = {} }) => <JobView hideProgress={true} title={'Start Update Engine'} />
const JobViewButton = ({ record = {} }) => <JobView hideProgress={true} title={record.jobStatus} id={record.id} />
const CreatedLocalDateTime = ({ record }) => {
  //var localFormat = 'YYYY-MM-DD[T]HH:mm:ss';
  return <span>{record.createdAt != null && record.createdAt && `${Moment(Moment.utc(record.createdAt).toDate()).tz(Moment.tz.guess()).format('MM/DD/YYYY, h:mm:ss A z')} `}</span>
};
const CompletedAtLocalDateTime = ({ record }) => {
  //var localFormat = 'YYYY-MM-DD[T]HH:mm:ss';
  return <span>{record.completedAt != null && record.completedAt && `${Moment(Moment.utc(record.completedAt).toDate()).tz(Moment.tz.guess()).format('MM/DD/YYYY, h:mm:ss A z')} `}</span>
};
class UpdateEngineListSection extends Component {
  render() {
    return <section className='section'>
      <List title="Jobs" perPage={localStorage.getItem('jobs/update-engine/perPage') ? localStorage.getItem('jobs/update-engine/perPage') : 10} sort={{ field: 'id', order: 'DESC' }} pagination={< UpdateEnginePagination />}  filters={<UpdateEngineFilter />} filterDefaultValues={{ spaJobType: 'TAP_UPDATE_ENGINE' }} bulkActionButtons={false}  actions={<UpdateEngineActions />} {...this.props}>
          <Datagrid>
              <TextField source="id" />
              <JobViewButton label="Status" />
              <NumberField source="progressCount" label="Deals Processed" />
              <NumberField source="errorCount" label="Errors"  />
              <CreatedLocalDateTime source="createdAt" label="Created" showTime  />
              <CompletedAtLocalDateTime source="completedAt" label="Completed" showTime />
          </Datagrid>
      </List>
    </section>
  }
};

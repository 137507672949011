import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { Button, Loading, useRefresh } from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';

import FileIcon from '@mui/icons-material/UploadFile';
import StartIcon from '@mui/icons-material/FileUpload';
import Typography from '@material-ui/core/Typography';
import { FilePicker } from 'react-file-picker'
import { toast } from 'react-toastify';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';
import parse from 'html-react-parser';

class ViewFileUploadDrawer extends Component {
    state = {
      uploadType: null,
      showPanel: false,
      message: undefined,
      errors: [],
      isLoading: true,
      data: {},
      isComplete: false,
      progress: 0,
      numErrorRows: 0,
      numProcessedRows: 0,
      numSuccessRows: 0,
      errorMessages: [],
      processing: false,
      jobInterval: 0
    };

    fetchJob = async (jobId) => {
        const token = localStorage.getItem('tap-token');
        const { errorMessages } = this.state;
        const requestOptions = {
           method: 'GET',
           headers: { 'Content-Type': 'application/json', Authorization: token},
        };
        return await fetch(process.env.REACT_APP_API_URL + '/jobs/' + jobId, requestOptions)
        .then(response => response.json())
        .then(job => {
          console.log("job ", job)
          if (job.jobStatus !== 'RUNNING' && job.jobStatus !== 'CREATED') {
            clearInterval(this.state.jobInterval);
            fetch(process.env.REACT_APP_API_URL + '/jobs/tasks/' + jobId, requestOptions)
            .then(response => response.json())
            .then(bulkImportResults => {
              //console.log("bulkImportResults ", bulkImportResults)
              var errorRecords = []
              bulkImportResults.map(task => {
                if (task.taskStatus === 'INVALID') {
                  return errorRecords.push( {
                      'recordIndex' : task.taskDetailsId,
                      'recordGroup' : task.taskDetails.substring(task.taskDetails.indexOf(', ') + 2, task.taskDetails.length),
                      'message' : task.statusMessage
                    }
                  )
                } else {
                  return {}
                }
              })

              if (errorRecords && errorRecords.length > 0) {
                var message = "Failed validations, data cannot be persisted."
                toast.error(message, {
                  position: toast.POSITION.BOTTOM_CENTER,
                  closeButton: true,
                  autoClose: false
                });
                var keyIndex = 0
                errorRecords.map(errorMessage => {
                  return errorMessages.push(<ListItem className="groupedTasks-Header" key={`groupedTasks-${errorMessage.recordIndex}-${keyIndex++}`}>
                    <ListItemText primary={<span>{errorMessage.recordGroup} - Row {errorMessage.recordIndex}</span>}
                    secondary={parse(errorMessage.message)} />
                  </ListItem>)
                })
                this.setState({
                  numErrorRows: errorMessages.length,
                  //numProcessedRows: bulkImportResults.numProcessedRows,
                  //numSuccessRows: bulkImportResults.numSuccessRows,
                  errorMessages: errorMessages,
                  processing: false
                });
              } else {
                toast.success("Rate Card Import Success", {
                  position: toast.POSITION.BOTTOM_CENTER,
                  closeButton: true,
                  autoClose: 5000
                });
                this.setState({
                  processing: false
                });
                var self = this;
                setTimeout(self.handleCloseClick(), 3000)
              }
            }).catch((exception) => {
              console.log("Caught exception ", exception);
              return []
            });
          }

        }).catch(({ status, statusText}) => {
          console.log("Caught status status ", status);
          console.log("Caught status statusText ", statusText);
          return []
        });
    };

    setFiles = (file) => {
      const { errorMessages } = this.state;
      this.setState({
        processing: true,
      });
      console.log("Inside setFiles ", file)
      console.log("file.name ", file.name)
      const formData = new FormData()
      formData.append('file', file, file.name);

      const token = localStorage.getItem('tap-token');
      const requestOptions = {
         method: 'POST',
         headers: {
           Authorization: token
         },
         body: formData
      };

      var url = process.env.REACT_APP_API_URL
      console.log("this.state.uploadType ",  this.state.uploadType)
      if (this.state.uploadType === 'WindowSequence')
        url += '/windowSequence/fileUpload'
      if (this.state.uploadType === 'LicensingJob')
        url += '/licensing/fileUpload'
      if (this.state.uploadType === 'RateCard')
        url += '/rateCard/fileUpload'
      if (this.state.uploadType === 'OfferUpdates')
        url += '/licensing/offer/fileUpload'

      fetch(url, requestOptions)
      .then(response => response.json())
      .then(responsData => {
        console.log("response ", responsData)
        var self = this
        if (this.state.uploadType === 'RateCard' || this.state.uploadType === 'OfferUpdates') {
          console.log("responsData ", responsData)
          var jobInterval = setInterval(function() {self.fetchJob(responsData)}, 2000);
          this.setState({ jobInterval: jobInterval });
        } else {
          console.log("responsData.errorMessages ", responsData.errorMessages)
          if ((responsData.error && responsData.error.message) || (responsData.errorMessages && responsData.errorMessages.length > 0)) {
            var message = responsData.error && responsData.error.message ? responsData.error.message : responsData.bulkStatus
            toast.error(message, {
              position: toast.POSITION.BOTTOM_CENTER,
              closeButton: true,
              autoClose: false
            });
            if (responsData.errorMessages) {
              responsData.errorMessages.map(errorMessage => {
                return errorMessages.push(<ListItem className="groupedTasks-Header" key={`groupedTasks-${errorMessage.recordIndex}`}>
                  <ListItemText primary={<span>{errorMessage.recordGroup} - Row {errorMessage.recordIndex}</span>}
                  secondary={parse(errorMessage.message)} />
                </ListItem>)
              })
            }
            this.setState({
              numErrorRows: responsData.numErrorRows,
              numProcessedRows: responsData.numProcessedRows,
              numSuccessRows: responsData.numSuccessRows,
              errorMessages: errorMessages,
              processing: false
            });
          } else {
            toast.success(responsData.bulkStatus, {
              position: toast.POSITION.BOTTOM_CENTER,
              closeButton: true,
              autoClose: 5000
            });
            this.setState({
              numErrorRows: responsData.numErrorRows,
              numProcessedRows: responsData.numProcessedRows,
              numSuccessRows: responsData.numSuccessRows,
              errorMessages: errorMessages,
              processing: false
            });
            setTimeout(self.handleCloseClick(), 3000)
          }
        }
      }).catch(({ status, statusText}) => {
        console.log("Caught status status ", status);
        console.log("Caught status statusText ", statusText);
        return []
      });

    }
    startUpdateEngine = async () => {
        const token = localStorage.getItem('tap-token');
        const requestOptions = {
           method: 'POST',
           headers: { 'Content-Type': 'application/json', Authorization: token},
        };
        return await fetch(process.env.REACT_APP_API_URL + '/FileUploads/update-engine/start', requestOptions)
        .then(response => response.json())
        .then(responsData => {
          return responsData
        }).catch(({ status, statusText}) => {
          console.log("Caught status status ", status);
          console.log("Caught status statusText ", statusText);
          return []
        });
    };
    handleClickShowFileUpload = async (uploadType) => {
      this.setState({
        showPanel: true,
        uploadType: uploadType,
        message: undefined,
        messageList: [],
        errors: [],
        isLoading: false,
        data: {},
        isComplete: false,
        progress: 0,
        errorMessages: []
      });
    };
    handleCloseClick = () => {
        document.getElementById('REFRESH').click()
        this.setState({ showPanel: false });
    };

    render() {
        function Refresh() {
          const refresh = useRefresh();
          return (
            <>
              <Button id='REFRESH' style={{ display: "none" }} onClick={() => refresh()} />
            </>
          )
        }
        function WindowRefresh() {
          return (
            <>
              <Button id='REFRESH' style={{ display: "none" }} onClick={() => window.location.reload()} label="Refresh" />
            </>
          )
        }
        const { showPanel, isLoading, processing, errorMessages, uploadType } = this.state;
        return (
            <Fragment>
                <div id="ViewIcon" className="ViewIcon">
                  { this.props.status !== "READY" && !this.props.id && <Button style={{float: 'left', marginLeft:'10px'}} label={this.props.title} onClick={() => this.handleClickShowFileUpload(this.props.type)}><StartIcon/></Button> }
                </div>
                <Drawer
                    anchor="right"
                    open={showPanel}
                    onClose={this.handleCloseClick}
                >
                    {isLoading ? <Loading/> : <div style={{ marginLeft: '30px', marginRight: '40px' }}>
                      <Fragment>
                            <Typography style={{ fontSize: "28px "}} variant="subtitle1" color="inherit" >File Upload View</Typography>
                            <hr />
                            {uploadType === 'WindowSequence' && <Typography style={{ fontSize: "17px ", padding: "25px"}} color="inherit" ><a href={'/upload_templates/WindowSequenceTemplate.xlsx'}>Download Template</a> </Typography>}
                            {uploadType === 'LicensingJob' && <Typography style={{ fontSize: "17px ", padding: "25px"}} color="inherit" ><a href={'/upload_templates/LicensingJob.xlsx'}>Download Template</a> </Typography>}
                            {uploadType === 'RateCard' && <Typography style={{ fontSize: "17px ", padding: "25px"}} color="inherit" ><a href={'/upload_templates/RateCard.xlsx'}>Download Template</a> </Typography>}

                            {processing && <LinearProgress style={{height: "5px", top: "55px", width: "100%", margin: "0" }}/> }
                            <FilePicker
                               extensions={['xlsx']}
                               onChange={FileObject => (this.setFiles(FileObject))}
                               onError={errMsg => {
                                   toast.error(errMsg, {
                                     position: toast.POSITION.BOTTOM_CENTER,
                                     closeButton: true,
                                     autoClose: false
                                   });
                                 }
                               }>
                               <Button label="File Upload"><FileIcon /></Button>
                            </FilePicker>
                            <div style={{ color: 'red' }}>{errorMessages && errorMessages.map(message => message)}</div>
                            <div style={{ zIndex: 1, textAlign: 'center', position: 'fixed', bottom: '0', float: 'left', right: '0' }}>
                              <Button
                                style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '20px'}}
                                label="Close"
                                onClick={this.handleCloseClick}>
                                  <IconCancel />
                             </Button>
                          </div>
                      </Fragment>
                    </div>}
                    {uploadType !== 'OfferUpdates' && <Refresh/>}
                    {uploadType === 'OfferUpdates' && <WindowRefresh/>}
                </Drawer>
            </Fragment>
        );
    }
}
const FileUploadView = connect()(ViewFileUploadDrawer);
export default FileUploadView

// src/screens/Login.js
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { ToastContainer } from 'react-toastify';
import * as qs from 'query-string';
import { toast } from 'react-toastify';
import parse from 'html-react-parser';

class MyLoginPage extends React.Component {
    state = {
      errorMessage : null
    };
    componentDidMount() {
        function urlify(text) {
            var urlRegex = /^(https?:\/\/)+([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
            return text.replace(urlRegex, function(url) {
                return '<a target="_blank" style="color: inherit; text-decoration: underline;" href="' + url + '">' + url + '</a>';
            })
        }
        const parsed = qs.parse(this.props.location.search)
        var error = parsed.error;
        if (error) {
          var array = error.split(' '), updatedResponse="";
          for(var i=0; i<array.length; i++) {
          	updatedResponse+=urlify(array[i])+" ";
          }
          toast.error("Unauthorized User", {
              position: toast.POSITION.BOTTOM_CENTER,
              closeButton: false,
              hideProgressBar: true,
              autoClose: false
          });
          this.setState({errorMessage : updatedResponse})
        }
    }
    render() {
      //const classes = useStyles();
      const submit = (e) => {
          e.preventDefault();
          window.location= process.env.REACT_APP_MY_ID_START_SSO_URL;
      };
      //const parsed = qs.parse(window.location.search);
      return (
            <div className='mainLogin'>
              <Card className='mainCard'>                  
                  <img style={{borderRadius: '25px'}} src={require('../images/tap_full_logo.png')} width='33%' alt='Disney Logo'  className="DisneyLogo" />
                  <CardHeader title="Disney's TAP: Transactional Automation Planning" className="DisneyWhiteBG" />
                  <CardContent className="DisneyWhiteBG">
                    <form onSubmit={submit}>
                        <input className='myIdButton' alt="login with myid" type="image" src={require('../images/loginWithMyID.png')} />
                    </form>
                </CardContent>
              </Card>
              {this.state.errorMessage && <Card className='mainCard'>
                  <CardContent className="DisneyWhiteBG" style={{ color: '#ed4337' }}>{parse(this.state.errorMessage)}</CardContent>
              </Card> }
              <ToastContainer />
            </div>
      );
    }
};

export default MyLoginPage;

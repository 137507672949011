import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { Button } from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import ViewIcon from '@material-ui/icons/RemoveRedEye';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextFieldCore from '@material-ui/core/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Moment from 'moment-timezone';

class ViewLicensingDrawer extends Component {
    state = {
      showPanel: false,
      message: undefined,
      errors: [],
      data: {},
      catalogs: [],
      deals: [],
      medias : [],
      territories: [],
      allHistories :[],
      currentLicensingJob: null,
      currentCatalogs: [],
      currentDeals: [],
      currentMedias: [],
      currentTerritories: [],
      history: null,
      catalogsInHistory: [],
      dealsInHistory: [],
      mediasInHistory: [],
      territoriesInHistory: []
    };
    changeSelectedHistoryToView = (event, inputValue) => {
      const { currentLicensingJob, currentCatalogs, currentDeals, currentMedias, currentTerritories } = this.state;
      if (inputValue != null) {
        var historicalCatalogs = []
        var historicalDeals = []
        var historicalMedias = []
        var historicalTerritories = []
        inputValue.catalogs.map(catalog => {
          return historicalCatalogs.push(<ListItem className="Catalog-Header" key={`catalog-${catalog.id}`}>
            <ListItemText primary={<span>{catalog.id}</span>} secondary={<span>{catalog.name}</span>} />
            </ListItem>)
        })
        inputValue.deals.map(deal => {
          return historicalDeals.push(<ListItem className="Deal-Header" key={`deal-${deal.id}`}>
            <ListItemText primary={<span>{deal.id}</span>} secondary={<span>{deal.name}</span>} />
            </ListItem>)
        })
        inputValue.medias.map(media => {
          return historicalMedias.push(<ListItem className="Media-Header" key={`media-${media.id}`}>
            <ListItemText primary={<span>{media.name}<br/></span>} />
          </ListItem>)
        })
        inputValue.territories.map(ter => {
          return historicalTerritories.push(<ListItem className="Territory-Header" key={`territory-${ter.id}`}>
            <ListItemText primary={<span>{ter.name}<br/></span>} />
          </ListItem>)
        })
        this.setState({
          history: inputValue,
          catalogsInHistory: historicalCatalogs,
          dealsInHistory : historicalDeals,
          mediasInHistory: historicalMedias,
          territoriesInHistory: historicalTerritories
        })
      } else {
        this.setState({
          data: currentLicensingJob,
          catalogs: currentCatalogs,
          deals: currentDeals,
          medias: currentMedias,
          territories: currentTerritories,
          history: null,
          catalogsInHistory: [],
          mediasInHistory: [],
          territoriesInHistory: []
        })
      }
    };
    fetchHistory = async (licensingId) => {
      const token = localStorage.getItem('tap-token');
      const requestOptions = {
         method: 'GET',
         headers: { 'Content-Type': 'application/json', Authorization: token},
      };
      return await fetch(process.env.REACT_APP_API_URL + '/licensing/' + licensingId + '/history', requestOptions)
      .then(response => response.json())
      .then(responsData => {
        return responsData
      }).catch(({ status, statusText}) => {
        console.log("Caught status status ", status);
        console.log("Caught status statusText ", statusText);
        return []
      });
    };
    handleClick = async () => {
      var errors = []
      const token = localStorage.getItem('tap-token');
      const requestOptions = {
         method: 'GET',
         headers: { 'Content-Type': 'application/json', Authorization: token},
      };
      if (this.props.id) {
        const allHistories = await this.fetchHistory(this.props.id);
        return await fetch(process.env.REACT_APP_API_URL + '/licensing/list/' + this.props.id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          var catalogs = []
          var deals = []
          var medias = []
          var territories = []
          responseData.catalogs.map(catalog => {
            return catalogs.push(<ListItem className="Catalog-Header" key={`catalog-${catalog.id}`}>
              <ListItemText primary={<span>{catalog.id}</span>} secondary={<span>{catalog.name}</span>} />
            </ListItem>)
          })
          responseData.deals.map(deal => {
            return deals.push(<ListItem className="Deal-Header" key={`deal-${deal.id}`}>
              <ListItemText primary={<span>{deal.id}</span>} secondary={<span>{deal.name}</span>} />
            </ListItem>)
          })
          responseData.medias.map(media => {
            return medias.push(<ListItem className="Media-Header" key={`media-${media.id}`}>
              <ListItemText primary={<span>{media.name}<br/></span>} />
            </ListItem>)
          })
          responseData.territories.map(ter => {
            return territories.push(<ListItem className="Territory-Header" key={`territory-${ter.id}`}>
              <ListItemText primary={<span>{ter.name}<br/></span>} />
            </ListItem>)
          })
          this.setState({
            data: responseData,
            catalogs: catalogs,
            deals: deals,
            medias: medias,
            territories: territories,
            errors : errors,
            showPanel: true,
            allHistories: allHistories,
            currentLicensingJob: responseData,
            currentCatalogs: catalogs,
            currentDeals: deals,
            currentMedias : medias,
            currentTerritories : territories
          })
        }).catch(({ status, statusText}) => {
          console.log("Caught status status ", status);
          console.log("Caught status statusText ", statusText);
        });
      } else {
        this.setState({
          showPanel: true,
          errors : []
        });
      }
    };
    handleCloseClick = (event: object, reason: string) => {      
      if (reason == null || reason !== 'backdropClick') {
        this.setState({ showPanel: false });
      }
    };
    render() {
        const { showPanel, data, errors, catalogs, deals, medias, territories, allHistories, history, catalogsInHistory, dealsInHistory, mediasInHistory, territoriesInHistory } = this.state;
        var localFormat = 'YYYY-MM-DD[T]HH:mm:ss';
        return (
            <Fragment>
                <div id="ViewIcon" className="ViewIcon">
                  <Button onClick={this.handleClick}><ViewIcon/></Button>
                </div>
                <Drawer
                    disableEscapeKeyDown
                    anchor="right"
                    open={showPanel}
                    onClose={this.handleCloseClick}                    
                >
                    <div style={{ marginLeft: '30px', marginRight: '40px', marginTop: '20px' }}>
                      <Fragment>
                            <Typography style={{ borderBottom: '2px solid black', borderBottomColor:"#000099", fontSize: "28px", minHeight: "80px"}} variant="subtitle1" color="inherit" >Licensing Job
                              <Autocomplete
                               style={{ width: "50%", float: "right", paddingTop: "1%", marginBottom: '1%'}}
                               autoHighlight
                               onChange={this.changeSelectedHistoryToView}
                               isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
                               getOptionLabel={(option) => Moment(Moment.utc(option.historyCreatedAt).toDate()).tz(Moment.tz.guess()).format('MM/DD/YYYY, h:mm:ss A z') + ' | ' + option.historyCreatedBy.username }
                               options={allHistories}
                               renderInput={(params) => (
                                 <TextFieldCore
                                   {...params}
                                   label="History"
                                   onChange={this.changeSelectedHistoryToView}
                                   variant="outlined"
                                   InputProps={{
                                     ...params.InputProps,
                                     endAdornment: (
                                       <React.Fragment>
                                         {params.InputProps.endAdornment}
                                       </React.Fragment>
                                     ),
                                   }}
                                 />
                               )}
                            />
                            </Typography>
                            <div style={{ float: "left", width: "48%"}} >
                            <div style={{ marginBottom: '10px' }}/>
                            <Typography style={{ fontSize: "18px "}} variant="inherit" color="inherit" >{data && data.id} - </Typography>
                            <Typography style={{ fontSize: "18px "}} variant="inherit" color="inherit" >{data && data.description} </Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            <Typography style={{ fontSize: "18px "}} variant="inherit" color="inherit" >{data && data.status}</Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            <Typography style={{ fontSize: "16px "}} variant="inherit" color="inherit" >Start Date: {data && Moment(Moment(data.startDate).local().format(localFormat)).format('MM/DD/YYYY')}</Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            <Typography style={{ fontSize: "16px "}} variant="inherit" color="inherit" >End Date: {data && Moment(Moment(data.endDate).local().format(localFormat)).format('MM/DD/YYYY')}</Typography>
                            <div style={{ marginBottom: '20px' }}/>
                            <Typography style={{ fontSize: "18px "}} variant="subtitle2" color="inherit" >Catalog</Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            {catalogs && catalogs.length === 0 && <span>No Catalogs</span>}
                            {catalogs && catalogs.map(catalog => catalog)}
                            <div style={{ marginBottom: '20px' }}/>
                            <Typography style={{ fontSize: "18px "}} variant="subtitle2" color="inherit" >Deal</Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            {deals && deals.length === 0 && <span>No Deals</span>}
                            {deals && deals.map(deal => deal)}
                            <div style={{ marginBottom: '10px' }}/>
                            <Typography style={{ fontSize: "18px "}} variant="subtitle2" color="inherit" >Media</Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            {medias && medias.length === 0 && <span>No Medias</span>}
                            {medias && medias.map(media => media)}
                            <Typography style={{ fontSize: "18px "}} variant="subtitle2" color="inherit" >Territory</Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            {territories && territories.length === 0 && <span>No Territories</span>}
                            {territories && territories.map(ter => ter)}
                            <div style={{ marginBottom: '10px' }}/>
                            </div>
                            {history != null && <div style={{ borderLeft: '2px solid black', borderLeftColor:"#000099",  paddingLeft: '1%', float: "left", width: "48%"}} >
                            <Typography style={{ fontSize: "18px "}} variant="inherit" color="inherit" >{history && history.id} - </Typography>
                            <Typography style={{ fontSize: "18px "}} variant="inherit" color="inherit" >{history && history.description} </Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            <Typography style={{ fontSize: "18px "}} variant="inherit" color="inherit" >{history && history.status}</Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            <Typography style={{ fontSize: "16px "}} variant="inherit" color="inherit" >Start Date: {history && Moment(Moment(history.startDate).local().format(localFormat)).format('MM/DD/YYYY')}</Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            <Typography style={{ fontSize: "16px "}} variant="inherit" color="inherit" >End Date: {history && Moment(Moment(history.endDate).local().format(localFormat)).format('MM/DD/YYYY')}</Typography>
                            <div style={{ marginBottom: '20px' }}/>
                            <Typography style={{ fontSize: "18px "}} variant="subtitle2" color="inherit" >Catalog</Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            {catalogsInHistory && catalogsInHistory.length === 0 && <span>No Catalogs</span>}
                            {catalogsInHistory && catalogsInHistory.map(catalog => catalog)}                          
                            <div style={{ marginBottom: '20px' }}/>
                            <Typography style={{ fontSize: "18px "}} variant="subtitle2" color="inherit" >Deal</Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            {dealsInHistory && dealsInHistory.length === 0 && <span>No Deals</span>}
                            {dealsInHistory && dealsInHistory.map(deal => deal)}
                            <div style={{ marginBottom: '10px' }}/>
                            <Typography style={{ fontSize: "18px "}} variant="subtitle2" color="inherit" >Media</Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            {mediasInHistory && mediasInHistory.length === 0 && <span>No Medias</span>}
                            {mediasInHistory && mediasInHistory.map(media => media)}
                            <Typography style={{ fontSize: "18px "}} variant="subtitle2" color="inherit" >Territory</Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            {territoriesInHistory && territoriesInHistory.length === 0 && <span>No Territories</span>}
                            {territoriesInHistory && territoriesInHistory.map(ter => ter)}
                            <div style={{ marginBottom: '10px' }}/>
                            </div>}
                            <div style={{ marginBottom: '50px' }}/>
                            {errors}
                            <div style={{ zIndex: 1, textAlign: 'center', position: 'fixed', bottom: '0', float: 'left', right: '0' }}>
                              <Button
                                style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '20px'}}
                                label="Close"
                                onClick={this.handleCloseClick}>
                                  <IconCancel />
                             </Button>
                          </div>
                      </Fragment>
                    </div>
                </Drawer>
            </Fragment>
        );
    }
}
const ViewLicensing = connect()(ViewLicensingDrawer);
export default ViewLicensing

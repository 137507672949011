import * as React from "react";
import { Admin, Resource } from 'react-admin';
import LoginPage from './screens/Login';
import TAPLayout from './layout/TAPLayout';
import Dashboard from './screens/Dashboard';
import AuthProvider from './TAPAuthProvider';
import DataProvider from './TAPDataProvider';
import { SPAUsersList, SPAUsersEdit, SPAUsersCreate } from './screens/SPAUsers';
import { WindowSequenceList } from './screens/windows/WindowSequence';
import { DefaultPricingList } from './screens/defaultPricing/DefaultPricing';
import { LicensingListRefresh } from './screens/licensing/Licensing';
import { WindowingSequenceCreate, WindowingSequenceEdit } from './screens/windows/EditWindowSequence';
import { LicensingCreate, LicensingEdit } from './screens/licensing/EditCreateLicensing';
import { RateCardList } from './screens/ratecards/RateCards';
import { SPAUsersRoleShow } from './screens/SPAUserRoles';
import { UpdateEngineList } from './screens/jobs/UpdateEngine'
import { PmxcuratedDatesAndPriceList } from './screens/licensing/PmxCuratedDatesAndPricing'
import { TAPJobsList, JobsTaskList } from './screens/jobs/TAPJobs'

import './App.css';
import { myTheme } from './theme/Theme';
import * as Config from './Config'
import { ToastContainer } from 'react-toastify';
import OfferTable from "./screens/licensing/OfferTable";
import OfferTableHistory from "./screens/licensing/OfferTableHistory";
import { DeliveryPMXList } from './screens/jobs/PMXReport.js'

class App extends React.Component {

  constructor(props) {
    super(props)
    if (window.location.href && window.location.href.includes("advanced"))
      console.log("window.location.href", window.location.href.includes("advanced"))
    this.state = {
     admin : false,
     rightsLineProductGate: false,
     jobsTracker: false,
     bulkDateMathTool: false,
     licensing:false,
     advancedDateMathCreate: window.location.href && window.location.href.includes("advanced"),
    }
    var permissions;
    AuthProvider.getPermissions().then(retPermissions => {
      permissions = retPermissions;
      if (permissions && permissions?.split(',').includes(Config.ROLE_ADMINISTRATOR)) {
        this.setState({ admin: true });
      }
      if (permissions && permissions?.split(',').includes(Config.ROLE_RIGHTSLINE_PRODUCT_GATE)) {
        this.setState({ rightsLineProductGate: true });
      }
      if (permissions && permissions?.split(',').includes(Config.ROLE_JOB_TRACKER)) {
        this.setState({ jobsTracker: true });
      }
      if (permissions && permissions?.split(',').includes(Config.ROLE_BULK_DATE_MATH_TOOL)) {
        this.setState({ bulkDateMathTool: true });
      }

    }).catch(function (error) {
      //console.log("permissions error ", error);
      //location.reload();
    });
  }
  //console.log("value ", value);
  render() {
    return <Admin authProvider={AuthProvider}  dataProvider={DataProvider}  loginPage={LoginPage}  theme={myTheme} layout={TAPLayout}  dashboard={Dashboard} >
        <Resource name="users" options={{ label: 'Users' }} list={ this.state.admin ? SPAUsersList : null} edit={SPAUsersEdit} create={SPAUsersCreate} />
        <Resource name="windowSequence" options={{ label: 'Windows' }} list={ WindowSequenceList } create={ WindowingSequenceCreate } edit={ WindowingSequenceEdit } />
        <Resource name="spaUserRoles" show={ SPAUsersRoleShow } edit={ SPAUsersRoleShow }/>
        <Resource name="rateCard" options={{ label: 'Rate Cards' }} list={RateCardList}  />
        <Resource name="lists/transactionalWindows" />
        <Resource name="lists/currency" />
        <Resource name="licensing/list" list={ LicensingListRefresh } create={ LicensingCreate } edit={ LicensingEdit } />
        <Resource name="licensing/getMediaTerritoryList/territory"/>
        <Resource name="licensing/getMediaTerritoryList/media"/>
        <Resource name="licensing/jobStatuses"/>
        <Resource name="licensing/searchCatalogItemsAPI"/>
        <Resource name="licensing/searchDealsAPI"/>
        <Resource name="defaultPricing" list={DefaultPricingList}/>
        <Resource name="jobs/update-engine" list={UpdateEngineList}  />
        <Resource name="jobs/tasks" options={{ label: 'Job Tasks' }} list={ JobsTaskList } />
        <Resource name="jobs/jobStatuses" />
        <Resource name="jobs/jobTypes" />
        <Resource name="pmxCuratedDatesAndPrices/distinctTerritories" />
        <Resource name="licensing/offerTable" list={OfferTable}/>
        <Resource name="licensing/offerTableHistory" list={OfferTableHistory}/>
        <Resource name="pmxCuratedDatesAndPrices" list={PmxcuratedDatesAndPriceList} />
        <Resource name="pmx/release/deliveries" list={DeliveryPMXList} />
        <Resource name="jobs/tap-jobs" list={TAPJobsList} />
        <Resource name="pmxCuratedDatesAndPrices/distinctProductPlatforms" />

        <ToastContainer style={{ width: "35%" }} />
    </Admin>
  };
};

export default App;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { crudGetOne, UserMenu } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const myCustomIconStyle = {
    avatar: {
        height: 30,
        width: 30,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundColor: '#4265C3'
    }
};
const MyCustomIcon = withStyles(myCustomIconStyle)(
    ({ classes }) => (
        <Avatar
            className={classes.avatar}
            src={require('../images/DMEDIconRender.jpg')}
        />
    )
);

class TAPUserMenuView extends Component {
    fetchProfile = () => {
        this.props.crudGetOne(
            // The resource
            'profile',
            // The id of the resource item to fetch
            'profile',
            // The base path. Mainly used on failure to fetch the data
            '/profile',
            // Whether to refresh the current view. I don't need it here
            true
        );
    };

    render() {
        const { crudGetOne, profile, ...props } = this.props;
        return (
            <UserMenu  icon={<MyCustomIcon />} label={profile ? profile.nickname : ''} {...props}>
            </UserMenu>
        );
    }
}

const mapStateToProps = state => {
    const resource = 'profile';
    const id = 'my-profile';
    const profileState = state.admin.resources[resource];

    return {
        profile: profileState ? profileState.data[id] : null
    };
};

const TAPUserMenu = connect(
    mapStateToProps,
    { crudGetOne }
)(TAPUserMenuView);
export default TAPUserMenu;

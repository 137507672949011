import React, {Fragment} from 'react';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MovieIcon from '@material-ui/icons/MovieFilter';
import { Button } from 'react-admin';
import CardIcon from './CardIcon';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Moment from 'moment';
import { connect } from 'react-redux';

const styles = {
    main: {
        flex: '1',
        marginRight: '1em',
        marginTop: 20,
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 16,
        minHeight: 52,
    },
    title: {
        textAlign: 'left',
        fontSize: 24,
    },
};


const RecentlyUpdatedPMX = withStyles(styles)(({ recentlyUpdatedPMX, translate, classes, isLoading }) => (
    <div className={classes.main}>
        <Card className={classes.card}>
            <CardIcon Icon={MovieIcon} bgColor={'blue'} />
            <Typography className={classes.title} color="textSecondary">
              Recently Updated PMX Catalogs
            </Typography>
            {isLoading === true ? <CircularProgress /> : <div>
              <Divider style={{ marginTop: '1em', marginBottom: '1em', width: '98%' }} />
              <List>
                  {recentlyUpdatedPMX && recentlyUpdatedPMX.length > 0 && recentlyUpdatedPMX.map(record => (
                    <Fragment key={record.id}>
                      <ListItem
                          key={record.id}
                      >
                          <ListItemText
                              primary={`${record.catalog_id} - ${record.catalog_name} - ${Moment(Moment.utc(record.modified_at).toDate()).tz(Moment.tz.guess()).format('DD-MMM-YYYY, h:mm:ss A z')}`}
                              className={classes.listItemText}
                          />
                          <Button
                            className={classes.button}
                            onClick={() => {window.location=`#/pmxCuratedDatesAndPrices?displayedFilters=%7B%7D&filter=%7B"catalogId"%3A"${record.catalog_id}"%7D&order=DESC&page=1&perPage=10&sort=modifiedAt`}}
                            label="View"
                          />
                      </ListItem>
                    </Fragment>
                  ))}
              </List>
            </div> }
        </Card>
    </div>
));


const mapStateToProps = state => ({
    //recentlyUpdatedPMX: state.admin.ui.recentlyUpdatedPMX,
});

export default connect(mapStateToProps)(RecentlyUpdatedPMX);

import React, { FC } from 'react';
import DashboardIcon from '@material-ui/icons/HomeTwoTone';
import DashboardIconSelected from '@material-ui/icons/Home';
import UsersIconSelected from '@material-ui/icons/People';
import UsersIcon from '@material-ui/icons/PeopleTwoTone';
import UpdateEngineIcon from '@mui/icons-material/ManageHistory';
import UpdateEngineIconSelected from '@mui/icons-material/ManageHistory';
import WindowsIcon from '@mui/icons-material/WindowTwoTone';
import WindowsIconSelected from '@mui/icons-material/Window';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import PlayingCardIcon from '@mui/icons-material/LocalAtm';
import PlayingCardIconSelected from '@mui/icons-material/LocalAtmTwoTone';
import MonetizationOnSharpIcon from '@mui/icons-material/MonetizationOnSharp';
import MonetizationOnSharpIconSelected from '@mui/icons-material/MonetizationOnTwoTone';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import RequestQuoteIconTwoTone from '@mui/icons-material/RequestQuoteTwoTone';
import PMXDeliveryIcon from '@material-ui/icons/MonetizationOnTwoTone';
import PMXDeliveryIconSelected from '@material-ui/icons/MonetizationOn';
import JobsIcon from '@material-ui/icons/WorkTwoTone';
import JobsIconSelected from '@material-ui/icons/Work';

import { useMediaQuery, Theme } from '@material-ui/core';
import { DashboardMenuItem, MenuItemLink, usePermissions } from 'react-admin';
import { AppState } from '../Types';
import * as Config from '../Config'
import { useSelector } from 'react-redux';

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
    const styles = {
      activeLink: {
        color: '#4265C3 !important',
        textDecoration: 'underline'
      },
      link: {
      },
      dashboardDiv: {
        paddingTop: "6px",
        width: "90vw"
      }
    };
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    var location = window.location.href ? window.location.href : "";
    useSelector((state: AppState) => state.theme); // force rerender on theme change
    const { permissions } = usePermissions();
    return (
        <div style={styles.dashboardDiv}>
            <DashboardMenuItem
              style={ String(location) && String(location).endsWith("#/") ? styles.activeLink : styles.link}
              leftIcon={String(location) && String(location).endsWith("#/") ? <DashboardIconSelected className="active" style={ styles.activeLink } /> : <DashboardIcon />}
              onClick={onMenuClick}
              primaryText={'Home'}
              sidebarIsOpen={open}
              dense={dense} />
            <MenuItemLink
                style={ String(location) && String(location).endsWith("/windowSequence") ? styles.activeLink : styles.link}
                to={`/windowSequence`}
                primaryText='Windows'
                leftIcon={String(location).endsWith("/windowSequence") ? <WindowsIconSelected className="active" style={ styles.activeLink } /> : <WindowsIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                style={ String(location) && String(location).endsWith("/rateCard") ? styles.activeLink : styles.link}
                to={`/rateCard`}
                primaryText='Rate Cards'
                leftIcon={String(location).endsWith("/rateCard") ? <PlayingCardIconSelected className="active" style={ styles.activeLink } /> : <PlayingCardIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                style={ String(location) && String(location).indexOf("licensing") > 0 ? styles.activeLink : styles.link}
                to={`/licensing/list`}
                primaryText="Licensing Jobs"
                leftIcon={String(location).indexOf("licensing") > 0 ? <MonetizationOnSharpIconSelected className="active" style={ styles.activeLink } /> : <MonetizationOnSharpIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                style={ String(location) && String(location).indexOf("/pmxCuratedDatesAndPrices") > 0 ? styles.activeLink : styles.link}
                to={`/pmxCuratedDatesAndPrices`}
                primaryText="PMX Prices and Dates"
                leftIcon={String(location).indexOf("/pmxCuratedDatesAndPrices") > 0 ? <RequestQuoteIconTwoTone className="active" style={ styles.activeLink } /> : <RequestQuoteIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            { false && <MenuItemLink
                style={ String(location) && String(location).indexOf("/defaultPricing") > 0 ? styles.activeLink : styles.link}
                to={`/defaultPricing`}
                primaryText="Default Pricing"
                leftIcon={String(location).indexOf("/defaultPricing") > 0 ? <PriceChangeIcon className="active" style={ styles.activeLink } /> : <PriceChangeIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />}
            <MenuItemLink
                  style={ String(location) && String(location).endsWith("/jobs/update-engine") ? styles.activeLink : styles.link}
                  to={`/jobs/update-engine`}
                  primaryText='Update Engine'
                  leftIcon={String(location).endsWith("/jobs/update-engine") ? <UpdateEngineIconSelected className="active" style={ styles.activeLink } /> : <UpdateEngineIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
            />
            {permissions && permissions?.split(',').includes(Config.ROLE_JOB_TRACKER) &&
              <><MenuItemLink
                    style={ String(location) && String(location).endsWith("/jobs/tap-jobs") ? styles.activeLink : styles.link}
                    to={`/jobs/tap-jobs`}
                    primaryText='TAP Jobs'
                    leftIcon={String(location).endsWith("/jobs/tap-jobs") ? <JobsIconSelected className="active" style={ styles.activeLink } /> : <JobsIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
              />
              <MenuItemLink
                     style={ String(location) && String(location).indexOf("/pmx/release/deliveries") > 0 ? styles.activeLink : styles.link}
                      to={`/pmx/release/deliveries`}
                      primaryText="PMX Deliveries"
                      leftIcon={String(location).indexOf("/pmx/release/deliveries") > 0 ? <PMXDeliveryIconSelected className="active" style={ styles.activeLink } /> : <PMXDeliveryIcon />}
                      onClick={onMenuClick}
                      sidebarIsOpen={open}
                      dense={dense}
              /></>
            }
            {permissions && permissions?.split(',').includes(Config.ROLE_ADMINISTRATOR) &&
              <MenuItemLink
                  style={ String(location) && String(location).indexOf("users") > 0 ? styles.activeLink : styles.link}
                  to={`/users`}
                  primaryText="Users"
                  leftIcon={String(location).indexOf("users") > 0 ? <UsersIconSelected className="active" style={ styles.activeLink } /> : <UsersIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
              />
            }
            {isXSmall && logout}
        </div>
    );
};
export default Menu;

import React, { Component }  from 'react';
import { List, Datagrid, TextField, ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';
import { TopToolbar, RefreshButton, TextInput, NumberField, ReferenceField, ReferenceInput, SelectInput } from 'react-admin';
import { Filter, ExportButton, Pagination, Link } from 'react-admin';
import { stringify } from 'query-string';
import JobView from '../../components/JobStatusPanel'
import Moment from 'moment-timezone';

const TAPJobsActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <TopToolbar>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }

        <RunUpdateEngineButton />
        <span style={{ width: '15px' }}>&nbsp;</span>
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
        <span style={{ width: '15px' }}>&nbsp;</span>
        <RefreshButton />
        {/* Add your custom actions
          <Button color="primary" onClick={customAction}>Custom Action</Button>
        */}
    </TopToolbar>
);

/*
<ReferenceField sortable={false} label="Job Type" reference="jobs/jobTypes" source="spaJobType">
    <SingleFieldList >
        <ChipField source="name" />
    </SingleFieldList>
</ReferenceField>
*/
const TAPJobsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Job IDs" source="jobIds" alwaysOn />
        <ReferenceInput style={{display: 'none'}} label="Job Types" source="spaJobType" reference="jobs/jobTypes" alwaysOn>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Job Types" source="spaJobType" reference="jobs/jobTypes" alwaysOn>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceArrayInput allowEmpty={false} label="Job Status" reference="jobs/jobStatuses" source="spaJobStatuses" alwaysOn>
          <AutocompleteArrayInput optionText="name" resettable  />
        </ReferenceArrayInput>
    </Filter>
);

const TAPJobsPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500]} {...props} />

const RunUpdateEngineButton = ({ record = {} }) => <JobView hideProgress={true} title={'Start Update Engine'} />
const JobViewButton = ({ record = {} }) => <JobView hideProgress={true} title={record.jobStatus} id={record.id} />
class TAPJobsSection extends React.Component {
  render() {
    return <section className='section'>
      <List title="Jobs" sort={{ field: 'id', order: 'DESC' }} pagination={< TAPJobsPagination />}  filters={<TAPJobsFilter />} filterDefaultValues={{ spaJobType: 'CURATED_PMX_DATES' }} bulkActionButtons={false}  actions={<TAPJobsActions />} {...this.props}>
          <Datagrid>
              <TextField source="id" />
              <ReferenceField label="Job Type" reference="jobs/jobTypes" source="jobType" link={false}>
                <TextField source="name" />
              </ReferenceField>
              <JobViewButton label="Status" />
              <LinkToJobTasks label="Target" />
              <NumberField source="progressCount" label="Progress" />
              <NumberField source="errorCount" label="Errors"  />
              <CreatedLocalDateTime source="createdAt" label="Created" showTime  />
              <CompletedAtLocalDateTime source="completedAt" label="Completed" showTime />
          </Datagrid>
      </List>
    </section>
  }
};


const LinkToJobTasks = ({ record }) => {
    return record.targetCount > 0 ? <Link to={{pathname: '/jobs/tasks', search: stringify({
                    page: 1,
                    perPage: 25,
                    sort: 'id',
                    order: 'DESC',
                    filter: JSON.stringify({ jobId: record.id }),
                })}}>{record.targetCount}</Link> : record.targetCount

};

export const TAPJobsList = props => (
  <TAPJobsSection {...props} />
);


const TAPJobsTasksFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Job ID" source="jobId" alwaysOn />
        <ReferenceInput label="Task Status" source="spaJobStatus" reference="jobs/jobStatuses" alwaysOn>
          <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);


class LinkToJobs extends Component {
    record = this.props;
    state = {
      jobId: 0
    }
    componentDidMount() {
      try {
        var searchString =  decodeURIComponent(this.record.location.search);
        var filterString = searchString.split('filter=')[1]
        var myParam = "{" + filterString.substr(1, filterString.indexOf("}&") - 1) + "}"
        this.setState({ jobId: JSON.parse(myParam).jobId });
      } catch(e) {
          console.error("Could not pull batch job id from search string");
      }
    }
    render() {
      const {jobId} = this.state;
      return  <div>
          <div className="breadcrumbs">
              <Link to={{pathname: '/jobs/tap-jobs', search: stringify({
                    page: 1,
                    perPage: 25,
                    sort: 'id',
                    order: 'DESC'
                })}}>Back To Jobs</Link> &nbsp; > Tasks for Job: {jobId}
            </div>
          </div>
    }
};

const CreatedLocalDateTime = ({ record }) => {
  return <span>{record.createdAt != null && record.createdAt && `${Moment(Moment.utc(record.createdAt).toDate()).tz(Moment.tz.guess()).format('MM/DD/YYYY, h:mm:ss A z')} `}</span>
};
const ModifiedAtAtLocalDateTime = ({ record }) => {
  return <span>{record.modifiedAt != null && record.modifiedAt && `${Moment(Moment.utc(record.modifiedAt).toDate()).tz(Moment.tz.guess()).format('MM/DD/YYYY, h:mm:ss A z')} `}</span>
};

const CompletedAtLocalDateTime = ({ record }) => {
  return <span>{record.completedAt != null && record.completedAt && `${Moment(Moment.utc(record.completedAt).toDate()).tz(Moment.tz.guess()).format('MM/DD/YYYY, h:mm:ss A z')} `}</span>
};

class JobsTaskListSection extends React.Component {
  render() {
    return <section className='section'>
      <LinkToJobs {...this.props} />
      <List title="Jobs Tasks" sort={{ field: 'createdAt', order: 'DESC' }} pagination={< TAPJobsPagination />}  filters={<TAPJobsTasksFilter />} bulkActionButtons={false}  {...this.props}>
          <Datagrid >
              <TextField source="jobId" />
              <TextField source="taskStatus" label="Status"  />
              <TextField source="statusMessage" label="Message" />
              <TextField source="taskDetails" label="Details"  />
              <CreatedLocalDateTime showTime source="createdAt" label="Created"  />
              <ModifiedAtAtLocalDateTime showTime source="modifiedAt" label="Modified" />
              <CompletedAtLocalDateTime showTime source="completedAt" label="Completed" />
          </Datagrid>
      </List>
    </section>
  }
}

export const JobsTaskList = props => (
  <JobsTaskListSection {...props} />
);

import * as React from 'react';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button} from 'react-admin';
import { confirmAlert } from 'react-confirm-alert';

class DeleteBulkButton extends Component {
  state = {
    jobInterval: 0
  };
  fetchJob = async (jobId) => {
      const token = localStorage.getItem('tap-token');
      const requestOptions = {
         method: 'GET',
         headers: { 'Content-Type': 'application/json', Authorization: token},
      };
      console.log("jobId " + jobId)
      return await fetch(process.env.REACT_APP_API_URL + '/jobs/' + jobId, requestOptions)
      .then(response => response.json())
      .then(job => {
        console.log("job ", job)
        if (job.httpCode !== 500 && job.jobStatus !== 'RUNNING' && job.jobStatus !== 'CREATED') {
          clearInterval(this.state.jobInterval);
          var successMessage = <div><div className="toastTitle">Success</div><div className="toastMainMessage">Selected offers deleted from RL, Please Wait...</div></div>
          toast.success(successMessage, {
            position: toast.POSITION.BOTTOM_CENTER,
            closeButton: true,
            autoClose: 2000,
            onClose: () => window.location.reload(true)
          });
        } else if (job.httpCode === 500 || job.jobStatus === 'FAILED') {
          clearInterval(this.state.jobInterval);
          var errorMessage = <div><div className="toastTitle">Error</div><div className="toastMainMessage">Error deleting offers to RL</div></div>
          toast.error(errorMessage, {
            position: toast.POSITION.BOTTOM_CENTER,
            closeButton: true,
            autoClose: false
          });
        }
      }).catch(({ status, statusText}) => {
        console.log("Caught status status ", status);
        console.log("Caught status statusText ", statusText);
        return []
      });
  };
  handleClick = () => {
    confirmAlert({
      title: 'Delete Offer(s)',
      message: 'Are you sure you want to delete the offer(s) from TAP2 and Rightsline? This action cannot be undone.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props.isLoading(true)
            var selectedRows = this.props.selectedrows.data
            var dataTable = this.props.dataTable
            var selectedRowIdPayload = []
            selectedRows.forEach(element => {
              selectedRowIdPayload.push(dataTable[element.dataIndex].id)
            });
            const token = localStorage.getItem('tap-token');
            const requestOptions = {
               method: 'POST',
               headers: { 'Content-Type': 'application/json', Authorization: token},
               body: JSON.stringify(selectedRowIdPayload)
            };
            fetch(process.env.REACT_APP_API_URL +  '/licensing/deleteOfferBulk', requestOptions).then(response => response.json())
            .then((responsData) => {
                if (responsData && typeof responsData !== 'object') {
                  var self = this
                  var jobInterval = setInterval(function() {self.fetchJob(responsData)}, 5000);
                  this.setState({ jobInterval: jobInterval });
                } else {
                  this.props.isLoading(false)
                  var errorMessage = <div><div className="toastTitle">Error</div><div className="toastMainMessage">{responsData.statusText}</div></div>
                  toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    closeButton: true,
                    autoClose: false
                  });
                }
            }).catch(({ status, statusText, headers, body }) => {
                console.log("Caught status status ", status);
                console.log("Caught status statusText ", statusText);
                console.log("Caught status body ", body);
            });
          }
        },
        {
          label: 'No',
          onClick: () => console.log("User cancelled delete to RL")
        }
      ]
    });
  }
      render() {
        return (
            <Fragment>
            <div id="deleteToRLbulk" style={{ float: 'right', marginLeft: 'auto', marginRight: '20px' }} >
              <Button style={{ float: 'center', marginLeft: 'center', color: '#F44336', }} label="Delete Offer(s)" onClick={this.handleClick} ><DeleteIcon/></Button>
            </div>
            </Fragment>
            )
      }
}

const DeleteOffer = connect()(DeleteBulkButton);
export default DeleteOffer

import React, {Fragment, Component} from 'react';
import { List, TextField, Datagrid,ReferenceArrayInput,
         Pagination, Filter, TextInput, TopToolbar,AutocompleteArrayInput,
         BulkExportButton, usePermissions, ExportButton, RadioButtonGroupInput
       } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { downloadCSV } from 'react-admin';
import RateCardEdit from './RateCardEdit.js';
import ViewRateCardDrawer from './RateCardView'
import BulkCopyButton from './BulkCopyButton';
import BulkDeleteButton from './BulkDeleteButton';
import JobRowStyle from '../../theme/JobRowStyle';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import * as Config from '../../Config'
import FileUploadView from '../../components/FileUploadPanel'
import Moment from 'moment-timezone';
import Tooltip from '@material-ui/core/Tooltip';

const exporter = rateCards => {
    const rateCardsForExport = rateCards.map(rateCard => {
        const {id,rateCardName, currency, priceType, revShareType, windowSequence, windowSequenceId, windows, tierAliases, actualTermEnd,
            status, actualTermStart, fixedTermEnd, fixedTermStart, createdById, createdAt, modifiedAt,
            modifiedById, modifiedBy, createdBy, ...rateCardForExport } = rateCard; // omit backlinks and author
        var windowsList = []
        rateCard.windows.forEach(item => {
            var window = {windowId: item.id.tapWindowId, windowName: item.window.windowName, percentage: item.percentage}
            windowsList.push(window)
        });
        var tiersList = []
        rateCard.tierAliases.forEach(item => {
            var tiers = []
            item.tiers.forEach(item2 => {
                var window = windowsList.filter(it => it.windowId === item2.id.tapWindowId)
                if(window.length > 0) {
                    var tier = {
                        rateCarName: rateCard.rateCardName, currency: rateCard.currency, priceType: rateCard.priceType,
                        revShareType: rateCard.revShareType, windowSequenceName: rateCard.windowSequence.windowSequenceName,
                        windowName : window[0].windowName, percentage: window[0].percentage,
                        tierName: item.tierAliasName, tierNumber: item2.id.tierNumber, tierFormat: item.tierAliasFormat.replaceAll(",", " | "), tier: item2.tierName, price:item2.price,
                        createdAt: rateCard.createdAt, modifiedAt: rateCard.modifiedAt,
                        createdBy: rateCard.createdBy.fullName, modifiedBy: rateCard.modifiedBy.fullName
                    }
                    tiers.push(tier)
                }
            });
            var tier = {tiers: tiers}
            tiersList.push(tier)
        });
        if(rateCard.tierAliases === null || rateCard.tierAliases === undefined || rateCard.tierAliases.length === 0 ){
            var tiers = []
            windowsList.forEach(it =>{
                var tier = {rateCarName: rateCard.rateCardName, currency: rateCard.currency, priceType: rateCard.priceType,
                    revShareType: rateCard.revShareType, windowSequenceName: rateCard.windowSequence.windowSequenceName,
                    windowName : it.windowName, percentage: it.percentage,
                    createdAt: rateCard.createdAt, modifiedAt: rateCard.modifiedAt,
                    createdBy: rateCard.createdBy.fullName, modifiedBy: rateCard.modifiedBy.fullName}
                tiers.push(tier)
            });
            var tier = {tiers: tiers}
            tiersList.push(tier)
        }
        rateCardForExport.tiers = tiersList
        return rateCardForExport;
    });
    jsonExport(rateCardsForExport, {
        headers: ['tiers.tiers.rateCarName', 'tiers.tiers.currency', 'tiers.tiers.priceType',
        'tiers.tiers.revShareType', 'tiers.tiers.windowSequenceName', 'tiers.tiers.windowName',
        'tiers.tiers.percentage',
        'tiers.tiers.tierName','tiers.tiers.tierFormat','tiers.tiers.tierNumber','tiers.tiers.tier','tiers.tiers.price',
        'tiers.tiers.createdBy', 'tiers.tiers.createdAt', 'tiers.tiers.modifiedBy', 'tiers.tiers.modifiedAt'],
        rename: ['Rate Card Name', 'Currency', 'Price Type',
        'Rev Share Type','Window Sequence Name', 'Window',
        'Rev Share Value',
        'Tier Name','Tier Format','Tier Number','Tier','Tier Price',
        'Created By', 'Created At', 'Modified By', 'Modified At']
    }, (err, csv) => {
        downloadCSV("\ufeff"+csv, 'ratecards'); // download as '.csv` file
    });
};


const RateCardActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
    permissions
}) => (
    <TopToolbar>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        <FileUploadButton />
        <span style={{ width: '15px' }}>&nbsp;</span>
        <ShowEditButtonAdmin label="Create" />
        <span style={{ width: '15px' }}>&nbsp;</span>
        <ExportButton
              disabled={total === 0}
              resource={resource}
              sort={currentSort}
              filter={filterValues}
              exporter={exporter}
        />
    </TopToolbar>
);

export class RateCardFilterComponent extends Component {
 render() {
   const validateSpecialCharacters = (name) => {
       const specialChars = /[`!@#$%^&*()_+\-={};':"\\|.<>/?~]/
       if (specialChars.test(name)) {
           return 'Input may not contain [`!@#$%^&*()_+-={};\':"\\|,.<>/?~]';
       }
       return undefined;
   }
   return <Filter {...this.props}>
       <TextInput type="number" label="Rate Card Id" source="jId" alwaysOn resettable validate={validateSpecialCharacters}/>
       <Tooltip title={"Please comma-separate multiple search keywords"} placement="top" alwaysOn resettable  validate={validateSpecialCharacters}>
            <TextInput label="Rate Card Name Keywords" source="rateCardName" alwaysOn resettable validate={validateSpecialCharacters} />
       </Tooltip>
        <RadioButtonGroupInput id="filterAnyAll" label="" source="includeAllOrAny" choices={[
            { id: 'all', name: 'Include All Keywords'},
            { id: 'any', name: 'Include Any Keywords' }
        ]} alwaysOn />
        <ReferenceArrayInput allowEmpty={false} label="Users" reference="users" source="users" alwaysOn filter={{"dataType": "rateCard"}} sort={{ field: 'fullName', order: 'ASC' }} perPage={200}>
          <AutocompleteArrayInput optionText="fullName" resettable  />
        </ReferenceArrayInput>
        <AutocompleteArrayInput allowEmpty={false} choices={choices} label="Status" source="statuses" optionText="name" resettable alwaysOn/>
   </Filter>
 }
}
export const choices = [
    {id : 'ACTIVE', name: 'ACTIVE'},
    {id : 'INACTIVE', name: 'INACTIVE'},
    {id : 'NEEDS_ATTENTION', name: 'NEEDS ATTENTION'}
];

const FileUploadButton = props => {
    const { permissions } = usePermissions();
    return <Fragment>
            { permissions && permissions?.split(',').includes(Config.ROLE_TAP_ADMIN) && <FileUploadView type={'RateCard'} title={'Upload'} /> }
    </Fragment>
};

export const RateCardFilter = props => (
    <RateCardFilterComponent {...props}  />
);

const BulkActionButtons = props => (
    <Fragment>
        <ShowBulkCopyButtonAdmin label="Copy" {...props} />
        <ShowBukDeleteButtonAdmin label="Delete" {...props} />
        <BulkExportButton {...props} />
    </Fragment>
);

const ShowBukDeleteButtonAdmin = props => {
    const { permissions } = usePermissions();
    return <Fragment>
            { permissions && permissions?.split(',').includes(Config.ROLE_TAP_ADMIN) && <BulkDeleteButton {...props}/> }
    </Fragment>
};

const ShowBulkCopyButtonAdmin = props => {
    const { permissions } = usePermissions();
    return <Fragment>
            { permissions && permissions?.split(',').includes(Config.ROLE_TAP_ADMIN) && <BulkCopyButton {...props}/> }
    </Fragment>
};

const ShowEditButtonAdmin = props => {
    const { permissions } = usePermissions();
    return <Fragment>
            { permissions && permissions?.split(',').includes(Config.ROLE_TAP_ADMIN) && <RateCardEdit id ={props?.record?.id}/> }
    </Fragment>
};

const RateCardViewButton = ({ record = {} }) => <ViewRateCardDrawer id={record.id} />

const StatusIcons = ({ record }) => <span title={record.status === "NEEDS_ATTENTION" ? 'Needs Attention' : record.status === "ACTIVE" ? 'Active' : 'Inactive'}>{record.status === "NEEDS_ATTENTION" ? <WarningAmberOutlinedIcon style={{ color: 'orange'}}  /> : record.status === "ACTIVE" ? <CheckCircleOutlinedIcon style={{ color: 'green'}} /> : <CancelOutlinedIcon style={{ color: 'red'}}/>}</span>

const RateCardPagination = props => <Pagination style={{ display: 'block' }} rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

const CreatedLocal = ({ record }) => {
  return <span>{record.createdAt != null && record.createdAt && `${Moment(Moment.utc(record.createdAt).toDate()).format('DD-MMM-YYYY')} `}</span>
};
const ModifiedAtAtLocal = ({ record }) => {
  return <span>{record.modifiedAt != null && record.modifiedAt && `${Moment(Moment.utc(record.modifiedAt).toDate()).tz(Moment.tz.guess()).format('DD-MMM-YYYY, h:mm:ss A z')} `}</span>
};
export const RateCardList = ({ permissions, ...props }) => (
  <Fragment>
    <List exporter={exporter} id="RateCardList" title="Rate Cards" bulkActionButtons={<BulkActionButtons  {...props} />} empty={false} actions={<RateCardActions {...props}/>} filters={<RateCardFilter />} {...props}  filterDefaultValues={{ status: "ACTIVE", includeAllOrAny: "all" }}  sort={{ field: 'id', order: 'DESC' }} perPage={localStorage.getItem('rateCard/perPage') ? localStorage.getItem('rateCard/perPage') : 10} pagination={< RateCardPagination />} >
          <Datagrid rowClick={null} rowStyle={JobRowStyle}>
              <RateCardViewButton source="view" sortable={false}  />
              <ShowEditButtonAdmin label="Edit" source="edit" sortable={false} {...props}/>
              <TextField source="id" label="Id" />
              <TextField source="rateCardName" label="Name" />
              <TextField source="windowSequence.windowSequenceName" label="Window Sequence" />
              <TextField source="currency" label="Currency" />
              <TextField source="priceType" label="Price Type" />
              <TextField source="revShareType" label="Rev Share" />
              <StatusIcons source="status" label="Status" />
              <TextField source="createdBy.fullName" label="Created By" />
              <CreatedLocal source="createdAt" label="Created" />
              <TextField source="modifiedBy.fullName" label="Updated By" />
              <ModifiedAtAtLocal source="modifiedAt" label="Modified" />
          </Datagrid>
      </List>
  </Fragment>
);

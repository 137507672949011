import React, {Fragment} from 'react';
import JobRowStyle from '../../theme/JobRowStyle';
import {List, Datagrid,TextField, Filter,
    Pagination, TopToolbar,ExportButton
} from 'react-admin';

import jsonExport from 'jsonexport/dist';
import { downloadCSV } from 'react-admin';


const exporter = records => {
    const forExport = records.map(item => {
        const {...pricingExport } = item;
        return pricingExport;
    });
    jsonExport(forExport, {
        headers: ['id', 'territory', 'currency', 'mediaType', 'defaultPricingWindowList.id','defaultPricingWindowList.window', 'defaultPricingWindowList.price'],
        rename: ['id', 'Territory', 'Currency',  'Media Type', 'id', 'Window', 'Price' ]
    }, (err, csv) => {
        downloadCSV(csv, 'defaultPricingTap');
    });
};

const DefaultPricingActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
    permissions
}) => (
    <TopToolbar>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        <ExportButton
              disabled={total === 0}
              resource={resource}
              sort={currentSort}
              filter={filterValues}
              exporter={exporter}
        />
    </TopToolbar>
);

const DefaultPricingFilter = (props) => (
    <Filter {...props}>
    </Filter>
);

const TagsField = ({ record }) => (
    <ul>
        {record.defaultPricingWindowList.map(item => (
            <li key={item.window}>{item.window} <b> {item.price !==null ? " - $ "+item.price : ""} </b>
            </li>
        ))}
    </ul>
)

const PaginationOptions = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
export const DefaultPricingList = ({ permissions, ...props }) => (
    <Fragment>
    <List exporter={exporter} id="defaultPricingList" title="Default Pricing" bulkActionButtons={false} empty={false} actions={<DefaultPricingActions {...props}/>} filters={<DefaultPricingFilter />} {...props}  sort={{ field: 'id', order: 'DESC' }} perPage={10} pagination={< PaginationOptions />} >
        <Datagrid rowClick={null} rowStyle={JobRowStyle}>
              <TextField source="id" label="Id" />
              <TextField source="territory" label="Territory" />
              <TextField source="currency" label="Currency" />
              <TextField source="mediaType" label="Media Type" />
              <TagsField source="defaultPricingWindowList" label="Windows" />
          </Datagrid>
      </List>
    </Fragment>
);

import * as React from 'react';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import StopIcon from '@mui/icons-material/StopCircle';
import { Button, useRedirect} from 'react-admin';
import { confirmAlert } from 'react-confirm-alert';

class InactivateButton extends Component {
  handleClick = () => {
    confirmAlert({
      title: 'Inactivate',
      message: 'Are you sure you wish to inactive the licensing job?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
              var jobId = this.props.id
              const token = localStorage.getItem('tap-token');
              const requestOptions = {
                  method: 'PUT',
                  headers: {'Content-Type': 'application/json', Authorization: token},
                  body: [jobId]
              };

              fetch(process.env.REACT_APP_API_URL + '/licensing/inactivate', requestOptions)
                  .then(response => response.json())
                  .then(body => {
                      if (body === true) {
                          var successMessage = <div>
                              <div className="toastTitle">Success</div>
                              <div className="toastMainMessage">"Job was Inactivated"</div>
                          </div>
                          toast.success(successMessage, {
                              position: toast.POSITION.BOTTOM_CENTER,
                              closeButton: true,
                              autoClose: 5000
                          });
                          document.getElementById('REDIRECT').click()
                          window.history.go();
                      } else {
                          if(body.httpCode === 400) {
                              var errMessage = <div>
                                  <div className="toastTitle">Error</div>
                                  <div className="toastMainMessage">{body.message}</div>
                              </div>
                              toast.error(errMessage, {
                                  position: toast.POSITION.BOTTOM_CENTER,
                                  closeButton: true,
                                  autoClose: 5000
                              });
                          } else {
                              var errorMessage = <div>
                                  <div className="toastTitle">Error</div>
                                  <div className="toastMainMessage">"Job is running, cannot be inactivated!"</div>
                              </div>
                              toast.error(errorMessage, {
                                  position: toast.POSITION.BOTTOM_CENTER,
                                  closeButton: true,
                                  autoClose: 5000
                              });
                          }
                      }
                  }).catch(({status, statusText}) => {
                  console.log("Caught status status ", status);
                  console.log("Caught status statusText ", statusText);
              })
          }
        },
        {
          label: 'No',
          onClick: () => console.log("User cancelled licensing job change")
        }
      ]
    });
  }
      render() {
        function Redirect() {
          const redirect = useRedirect();
          return (
            <>
              <Button id='REDIRECT' style={{ display: "none" }} onClick={() => redirect('/licensing/list')} />
            </>
          )
        }
        return (
            <Fragment>
            <div id="inactivateButton" style={{ float: 'right', marginLeft: 'auto' }} >
              <Button style={{ float: 'center', marginLeft: 'center', color: '#F44336', }} label="Inactivate" onClick={this.handleClick} {...this.props} >
                <StopIcon /></Button>
            <Redirect />
            </div>
            </Fragment>
            )
      }
}

const InactivateJob= connect()(InactivateButton);
export default InactivateJob

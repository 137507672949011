import * as React from 'react';
import {Fragment, useState} from 'react';
import StopIcon from '@mui/icons-material/StopCircle';
import {
    Button,
    Confirm,
    useRefresh,
    useNotify,
    useUnselectAll,
} from 'react-admin';

const BulkInactivateButton = ({ selectedIds }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();

    const deleteMany =  function () {
        setLoading(true);
        const token = localStorage.getItem('tap-token');
        const requestOptions = {
            method: 'PUT',
            headers: {'Content-Type': 'application/json', Authorization: token},
            body: JSON.stringify(selectedIds)
        };
        fetch(process.env.REACT_APP_API_URL + '/licensing/inactivateBulk/', requestOptions)
            .then(response => response.json())
            .then(body => {
                body.forEach(job => {
                    if(job.statusCodeValue === 500) {
                        notify('Some Licensing Job(s) are running, cannot be inactivated', { type: 'warning' });
                    } else if(job.statusCodeValue === 400) {
                        notify(JSON.parse(job.body).message, {type: 'warning'});
                    } else {
                        notify('Licensing Job(s) inactivated');
                    }
                });
                refresh();
                unselectAll('licensing/list');
                setLoading(false)
            })
            .catch(error => {
                console.log("error ", error)
                notify('Error: licensing jobs not inactivated', { type: 'warning' })
                setLoading(false)
            });
    };

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        deleteMany();
        setOpen(false);
    };

    return (
        <Fragment>
            <Button style={{color: '#F44336'}} label="Inactivate" onClick={handleClick}><StopIcon/></Button>
            <Confirm
                isOpen={open}
                loading={loading}
                title={"Inactivate Licensing Job" + (selectedIds.toString().indexOf(',') > 0 ? "s" : "")}
                content={"Are you sure you want to inactivate " + (selectedIds.toString().indexOf(',') > 0 ? "the selected licensing jobs" : "this licensing job?")}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
}

export default BulkInactivateButton;

import React from "react";
import { Link } from "react-router-dom";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import LoadingIndicator from './LoadingIndicator';
import { withStyles } from '@material-ui/core/styles';

function replaceBreadCrumbURL(url, match) {
  if (url === "/spaUserRoles")
    return "/users";
  if (url === "/datemath")
    return "/datemath/jobs";
  if (url === "/clearanceMemo")
    return "/clearanceMemo/sharedSections";
  return url;
}

function isIgnoreFirstPath(url) {
  if (url === "/datemath/jobs")
    return true;
  if (url === "/datemath/calculations")
    return true;
  if (url === "/clearanceMemo/sharedSections")
    return true;
  return false;
}

function includeBreadCrumbChevron(length, url) {
  if (url === "/datemath" && length === 1)
    return false;
  if (url === "/clearanceMemo" && length === 1)
      return false;
  return true;
}

function goBack() {
  window.history.go(-1); return false;
}

const styles = {
    button: {
      background: 'none',
      border: 'none',
      padding: '0',
      color: '#069',
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    link: {
      color: '#069',
    },
    hide: {
      display: 'none',
    }
};

const PureBreadcrumbs = withStyles(styles)(({ breadcrumbs, classes }) => (
  <div>
    <div className="breadcrumbs">
      {breadcrumbs.map(({ breadcrumb, match }, index) => (
        <div className={isIgnoreFirstPath(match.url) ? classes.hide : "bc"} key={match.url}>
          {match.url === "/datemath/calculations" &&  breadcrumbs.length !== 2 ? <button className={classes.button} onClick={goBack}>{breadcrumb}</button>  :
           index < breadcrumbs.length - 1 && match.url !== "/sourceReports" ? <Link className={classes.link} to={replaceBreadCrumbURL(match.url, match) || ""}>{breadcrumb}</Link> : <span>{breadcrumb}</span>}
           <span>{index < breadcrumbs.length - 1 && includeBreadCrumbChevron(breadcrumbs.length, match.url) && ">"}</span>
        </div>
      ))}
    </div>
    <LoadingIndicator />
  </div>
));

const DynamicUserEditBreadcrumb = ({ match }) => (
  <span>Edit User ({match.params.userId})</span>
);

const DynamicSpaUserRolesBreadcrumb = ({ match }) => (
  <span>Show Role ({match.params.spaUserRoleId})</span>
);

const DynamicProductBreadcrumb = ({ match }) => (
  <span>Show Product ({match.params.productId})</span>
);

const DynamicDateMathCalculations = ({ match }) => (
  <span>Date Math Calculations</span>
);

const routes = [
  { path: '/', breadcrumb: "Dashboard" },
  { path: '/users', breadcrumb: "Users" },
  { path: '/users/create', breadcrumb: 'Create User' },
  { path: '/users/:userId', breadcrumb: DynamicUserEditBreadcrumb },
  { path: '/spaUserRoles', breadcrumb: 'Users' },
  { path: '/sourceReports', breadcrumb: 'Reports' },
  { path: '/spaUserRoles/:spaUserRoleId', breadcrumb: DynamicSpaUserRolesBreadcrumb },
  { path: '/sourceReports/rightsLineProductUpdates', breadcrumb: 'Rightsline Product Gate' },
  { path: '/products', breadcrumb: 'Products' },
  { path: '/datemath', breadcrumb: 'Date Math Jobs' },
  { path: '/datemath/jobs/create', breadcrumb: 'Upload Date Math' },
  { path: '/datemath/jobs/simplecreate', breadcrumb: 'Simple Create' },
  { path: '/datemath/jobs/advancedcreate', breadcrumb: 'Advanced Create' },
  { path: '/datemath/calculations', breadcrumb: DynamicDateMathCalculations },
  { path: '/datemath/calculations/:dateMathCalculationId', breadcrumb: DynamicDateMathCalculations },
  { path: '/products/:productId/show', breadcrumb: DynamicProductBreadcrumb },
  { path: '/productErrorReport', breadcrumb: ' '},

];

export default withBreadcrumbs(routes, {excludePaths: ['/','/products/:productId/'] })(PureBreadcrumbs);

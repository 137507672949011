import * as React from 'react';
import { Fragment, useState } from 'react';
import PlayIcon from '@mui/icons-material/PlayCircleOutline';
import {
    Button,
    Confirm,
    useRefresh,
    useNotify,
    useUnselectAll,
} from 'react-admin';

const BulkRunButton = ({ selectedIds }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();

    const updateMany = function() {
        setLoading(true)
        const token = localStorage.getItem('tap-token');
        const requestOptions = {
           method: 'POST',
           headers: { 'Content-Type': 'application/json', Authorization: token},
           body: JSON.stringify(selectedIds)
        };
        fetch(process.env.REACT_APP_API_URL + '/licensing/runBulk', requestOptions)
        .then(responseData => {
            if(responseData.status === 400){
                notify('Some Licensing Job(s), cannot start to run, probably with inactived status', { type: 'warning' });
            }else{
                notify('Success, Job(s) are now running');
            }
          refresh();
          unselectAll('licensing/list');
          setLoading(false)
        }).catch(error  => {
          console.log("error ", error)
          notify('Error: licensing jobs cannot start to run', { type: 'warning' })
          setLoading(false)
        });
    };
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        updateMany();
        setOpen(false);
    };

    return (
        <Fragment>
            <Button label="Run" onClick={handleClick}><PlayIcon/></Button>
            <Confirm
                isOpen={open}
                loading={loading}
                title={"Run Licensing Job" + (selectedIds.toString().indexOf(',') > 0 ? "s" : "")}
                content={"Are you sure you want to run " + (selectedIds.toString().indexOf(',') > 0 ? "the selected licensing jobs?" : "this licensing job?")}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
}

export default BulkRunButton;

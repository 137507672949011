import React  from 'react';
import { List, Datagrid, TextField, DateField, Filter, Pagination, SelectInput } from 'react-admin';
import { TopToolbar, RefreshButton, CreateButton, TextInput, ReferenceArrayField, SingleFieldList, ChipField, ReferenceArrayInput, SelectArrayInput } from 'react-admin';
import { Edit, Create, SimpleForm, required, Toolbar, SaveButton, DeleteWithConfirmButton, ExportButton, AutocompleteArrayInput } from 'react-admin';
import Breadcrumbs from '../components/Breadcrumbs'
import { makeStyles } from '@material-ui/core/styles';
import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import * as Config from '../Config'
import Moment from 'moment-timezone';

const exporter = users => {
    const usersForExport = users.map(user => {
        const { username, ermUserName, spaUserRoleIds, password, ...userForExport } = user; // omit backlinks and author
        userForExport.email = user.username
        var roleList = []
        if (user.spaUserRoleIds.includes(parseInt(Config.ROLE_ADMINISTRATOR)))
          roleList.push('ADMINISTRATOR')
        if (user.spaUserRoleIds.includes(parseInt(Config.ROLE_RIGHTSLINE_PRODUCT_GATE)))
          roleList.push('RIGHTSLINE PRODUCT GATE')
        if (user.spaUserRoleIds.includes(parseInt(Config.ROLE_BULK_DATE_MATH_TOOL)))
          roleList.push('BULK DATE MATH TOOL')
        if (user.spaUserRoleIds.includes(parseInt(Config.ROLE_AIR_DATE_INGESTION_TOOL)))
          roleList.push('AIR DATE INGESTION TOOL')
        if (user.spaUserRoleIds.includes(parseInt(Config.ROLE_WON_ERROR_REPORT)))
          roleList.push('WON ERROR REPORT')
        if (user.spaUserRoleIds.includes(parseInt(Config.ROLE_APOLLO_ERROR_REPORT)))
          roleList.push('APOLLO ERROR REPORT')
        if (user.spaUserRoleIds.includes(parseInt(Config.ROLE_JOB_TRACKER)))
          roleList.push('JOB TRACKER')
        if (user.spaUserRoleIds.includes(parseInt(Config.ROLE_LEGAL_ADMIN)))
          roleList.push('LEGAL ADMIN')
        if (user.spaUserRoleIds.includes(parseInt(Config.ROLE_LEGAL_USER)))
          roleList.push('LEGAL USER')
        if (user.spaUserRoleIds.includes(parseInt(Config.ROLE_DEAL_VERSIONING)))
          roleList.push('DEAL VERSIONING')
        if (user.spaUserRoleIds.includes(parseInt(Config.ROLE_PRODUCT_COMMENTS_ADMIN)))
          roleList.push('PRODUCT COMMENTS ADMIN')
        userForExport.roles = roleList
        return userForExport;
    });
    jsonExport(usersForExport, {
        headers: ['id', 'email', 'fullName', 'rightslineId', 'roles', 'createdAt', 'modifiedAt'] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'users'); // download as 'posts.csv` file
    });
};

const SPAUsersActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <TopToolbar>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        <CreateButton basePath={basePath} />
        <span style={{ width: '15px' }}>&nbsp;</span>
        <ExportButton
              maxResults={1000000}
              disabled={total === 0}
              resource={resource}
              sort={currentSort}
              filter={filterValues}
              exporter={exporter}
        />
        <span style={{ width: '15px' }}>&nbsp;</span>
        <RefreshButton />
        {/* Add your custom actions
          <Button color="primary" onClick={customAction}>Custom Action</Button>
        */}
    </TopToolbar>
);

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="fullName" alwaysOn resettable />
        <TextInput label="Email" source="emailAddress" alwaysOn resettable />
        <ReferenceArrayInput allowEmpty={false} label="Roles" reference="spaUserRoles" source="roleIds" alwaysOn style={{minWidth: '200px'}}>
          <AutocompleteArrayInput optionText="roleName" resettable emptyValue={0} />
        </ReferenceArrayInput>
    </Filter>
);
export const SPAUsersList = props => (
  <SPAUsersListSection {...props} />
);
const PaginationOptions = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500]} {...props} />
class SPAUsersListSection extends React.Component {
  render() {
    return <section className='section'>
      <List exporter={exporter} title="Users" perPage={localStorage.getItem('users/perPage') ? localStorage.getItem('users/perPage') : 10} bulkActionButtons={false} pagination={<PaginationOptions />} filters={<UserFilter />}  actions={<SPAUsersActions />} {...this.props}>
          <Datagrid rowClick="edit" >
              <TextField source="id" />
              <TextField source="username" label="Email Address" />
              <TextField source="fullName" label="Name"  />
              <LastLoginLocalDateTime source="lastLogin" label="Last Login" />
              <TextField source="status" />
              <ReferenceArrayField style={{minHeight: '65px'}} sortable={false} label="Roles" reference="spaUserRoles" source="spaUserRoleIds">
                  <SingleFieldList>
                      <ChipField source="roleName" size="small" />
                  </SingleFieldList>
              </ReferenceArrayField>
              <TextField source="rightslineId" label="Rightsline ID" />
          </Datagrid>
      </List>
    </section>
  }
};
const LastLoginLocalDateTime = ({ record }) => {
  return <span>{record.lastLogin != null && record.lastLogin && `${Moment(Moment.utc(record.lastLogin).toDate()).tz(Moment.tz.guess()).format('MM/DD/YYYY, h:mm:ss A z')} `}</span>
};
const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});
const CustomToolbar = props => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton />
        <DeleteWithConfirmButton confirmTitle="Delete User" confirmContent="Are you sure you wish to delete this user?" />
    </Toolbar>
);
const EditUserTitle = ({ record }) => {
  return <span>Edit User {record ? `"${record.username}"` : ''}</span>;
};
class SPAUsersEditSection extends React.Component {
  render() {
    return <section className='section'>
      <Breadcrumbs />
      <Edit title={<EditUserTitle />} {...this.props}>
          <SimpleForm toolbar={<CustomToolbar />} >
              <TextField source="id" />
              <DateField source="createdAt" />
              <DateField source="modifiedAt" />
              <DateField source="lastLogin" />
              <TextInput source="username" validate={required()} />
              <TextInput source="fullName" />              
              <SelectInput source="status" validate={required()} defaultValue={'ACTIVE'} choices={[
                { id: 'ACTIVE', name: 'ACTIVE' },
                { id: 'DISABLED', name: 'DISABLED' },
                { id: 'INACTIVE', name: 'INACTIVE' },                
              ]} />
              <TextInput source="rightslineId" label="RL ID" />              
              <ReferenceArrayInput label="Roles" reference="spaUserRoles" source="spaUserRoleIds">
                <SelectArrayInput optionText="roleName"  />
              </ReferenceArrayInput>
          </SimpleForm>
      </Edit>
    </section>
  }
}
export const SPAUsersEdit = props => (
  <SPAUsersEditSection {...props} />
);

export const SPAUsersCreate = props => (
  <SPAUsersCreateSection {...props} />
);

class SPAUsersCreateSection  extends React.Component {
  render() {
    return <section className='section'>
      <Breadcrumbs />
      <Create title="Create User" {...this.props}>
          <SimpleForm>
              <TextInput source="username" validate={required()} />
              <ReferenceArrayInput label="Roles" reference="spaUserRoles" source="spaUserRoleIds">
                <SelectArrayInput optionText="roleName"  />
              </ReferenceArrayInput>
          </SimpleForm>
      </Create>
    </section>
  }
}

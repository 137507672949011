import React, { }  from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { Filter, Pagination, SelectInput, TextInput } from 'react-admin';
import Moment from 'moment-timezone';

export interface DataProviderProps {
    dataProvider: SPADataProvider,
    hideUpdates: true
}

export const actionChoices = [
    {id : 'CREATE', value: 'CREATE', name: 'Create'},
    {id : 'UPDATE', value: 'UPDATE', name: 'Update'},
    {id : 'DELETE', value: 'DELETE', name: 'Delete'}
];

export const statusChoices = [
    {id : 'READY', value: 'READY', name: 'Ready'},
    {id : 'COMPLETE', value: 'COMPLETE', name: 'Complete'},
    {id : 'ERROR', value: 'ERROR', name: 'Error'},
    {id : 'SKIPPED', value: 'SKIPPED', name: 'Skipped'},
    {id : 'HOLD', value: 'HOLD', name: 'Hold'},
    {id : 'IN_PROGRESS', value: 'IN_PROGRESS', name: 'In Progress'},
    {id : 'REPROCESS', value: 'REPROCESS', name: 'Reprocess'},
];

const PMXFilters = (props) => (
    <Filter {...props}>
        <TextInput lavel="Sku Id" source="skuId" resettable alwaysOn/>
        <SelectInput label="Action" source="action" choices={actionChoices} resettable alwaysOn />
        <SelectInput label="Status" source="status" choices={statusChoices} resettable alwaysOn />
    </Filter>
);

const PMXPagination = props => <Pagination rowsPerPageOptions={[25, 50, 100, 500]} {...props} />


const CreatedLocalDateTime = ({ record }) => {
  return <span>{record.createdAt != null && record.createdAt && `${Moment(Moment.utc(record.createdAt).toDate()).tz(Moment.tz.guess()).format('MM/DD/YYYY')} `}</span>
};
const ModifiedAtAtLocalDateTime = ({ record }) => {
  return <span>{record.modifiedAt != null && record.modifiedAt && `${Moment(Moment.utc(record.modifiedAt).toDate()).tz(Moment.tz.guess()).format('MM/DD/YYYY, h:mm:ss A z')} `}</span>
};

export const DeliveryPMXList = props => (
    <List title="PMX Deliveries" {...props} sort={{ field: 'id', order: 'DESC' }} perPage={25} pagination={< PMXPagination />} filters={<PMXFilters/>} >
        <Datagrid rowClick={null} >
            <TextField source="id" />
            <TextField source="action" />
            <TextField source="status" />
            <TextField source="pmxSku.pmxSkuId" sortable={false} label="SkuId" />
            <TextField source="pmxSku.pmxProduct.pmxProductId" sortable={false} label="ProductId" />
            <TextField source="pmxSku.pmxProduct.pmxRelease.pmxReleaseId" sortable={false} label="ReleaseId" />
            <TextField source="pmxSku.pmxProduct.pmxRelease.releaseName" sortable={false} label="Name" />
            <TextField source="pmxSku.pmxProduct.pmxRelease.releaseType" sortable={false} label="Type" />
            <TextField source="pmxSku.skuKeyDates[0].territory" sortable={false} label="Territory" />
            <DateField source="pmxSku.releaseDate" label="Release Date" sortable={false} style={{ maxWidth: '20px' }} />
            <TextField source="errorMsg" label="Error" />
            <CreatedLocalDateTime source="createdAt" label="Created" showTime  />
            <ModifiedAtAtLocalDateTime source="modifiedAt" label="Modified" showTime />
        </Datagrid>
    </List>
);

import React, {Fragment, Component} from 'react';
import { List, TextField, Datagrid, ReferenceArrayInput,AutocompleteArrayInput,
         Pagination, Filter, TextInput, TopToolbar,
         ExportButton, CreateButton, ReferenceArrayField, SingleFieldList,
         ChipField, BulkExportButton, EditButton, usePermissions
       } from 'react-admin';
import BulkCopyButton from './BulkCopyButton';
import BulkDeleteButton from './BulkDeleteButton';
import ViewWindowMacrosDrawer from './ViewWindowSequence'
import jsonExport from 'jsonexport/dist';
import { downloadCSV } from 'react-admin';
import JobRowStyle from '../../theme/JobRowStyle';
import * as Config from '../../Config'
import Moment from 'moment-timezone';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const exporter = windowSequences => {
    const windowSequencesForExport = windowSequences.map(windowSequence => {
        const { searchableTitle, windows, createdById,	modifiedById, modifiedBy, windowIdList, createdBy, ...windowSequenceForExport } = windowSequence; // omit backlinks and author
        windowSequenceForExport.windowNames = windowSequence.windows.map(window => {return window.windowName})
        windowSequenceForExport.status = windowSequence.status
        windowSequenceForExport.createdBy = windowSequence.createdBy.fullName
        windowSequenceForExport.modifiedBy = windowSequence.modifiedBy.fullName
        return windowSequenceForExport;
    });
    jsonExport(windowSequencesForExport, {
        headers: ['id', 'windowSequenceName', 'windowNames', 'status','createdBy', 'createdAt', 'modifiedBy', 'modifiedAt'], // order fields in the export
        rename: ['id', 'Window Sequence Name', 'Window Names', 'Status','Created By', 'Created At', 'Modified By', 'Modified At']
    }, (err, csv) => {
        downloadCSV("\ufeff"+csv, 'windows'); // download as 'posts.csv` file
    });
};


const WindowSequenceActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
    permissions
}) => (
    <TopToolbar>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        <ShowCreateButtonAdmin basePath={basePath} label="Create" />
        <span style={{ width: '15px' }}>&nbsp;</span>
        <ExportButton
              disabled={total === 0}
              resource={resource}
              sort={currentSort}
              filter={filterValues}
              exporter={exporter}
        />
    </TopToolbar>
);

const ShowCreateButtonAdmin = props => {
    const { permissions } = usePermissions();
    return <Fragment>
            { permissions && permissions?.split(',').includes(Config.ROLE_TAP_ADMIN) && <CreateButton {...props}/> }
    </Fragment>
};

const ShowEditButtonAdmin = props => {
    const { permissions } = usePermissions();
    return <Fragment>
            { permissions && permissions?.split(',').includes(Config.ROLE_TAP_ADMIN) && <EditButton label="Edit" source="edit" sortable={false} {...props}/> }
    </Fragment>
};

const ShowBukDeleteButtonAdmin = props => {
    const { permissions } = usePermissions();
    return <Fragment>
            { permissions && permissions?.split(',').includes(Config.ROLE_TAP_ADMIN) && <BulkDeleteButton label="Inactivate" {...props}/> }
    </Fragment>
};

const ShowBulkCopyButtonAdmin = props => {
    const { permissions } = usePermissions();
    return <Fragment>
            { permissions && permissions?.split(',').includes(Config.ROLE_TAP_ADMIN) && <BulkCopyButton label="Copy" {...props}/> }
    </Fragment>
};

export class WindowSequenceFilterComponent extends Component {
 render() {
   const validateSpecialCharacters = (name) => {
       const specialChars = /[`!@#$%^&*()_+\-={};':"\\|,.<>/?~]/
       if (specialChars.test(name)) {
           return 'Input may not contain [`!@#$%^&*()_+-={};\':"\\|,.<>/?~]';
       }
       return undefined;
   }
   return <Filter {...this.props}>
       <TextInput type="number" label="Window Id" source="jId" alwaysOn resettable validate={validateSpecialCharacters}/>
       <TextInput label="Window Name" source="windowSequenceName" alwaysOn resettable validate={validateSpecialCharacters} />
       <ReferenceArrayInput allowEmpty={false} label="Users" reference="users" source="users" alwaysOn filter={{"dataType": "windowSequence"}} sort={{ field: 'fullName', order: 'ASC' }} perPage={200}>
          <AutocompleteArrayInput optionText="fullName" resettable  />
        </ReferenceArrayInput>
        <AutocompleteArrayInput allowEmpty={false} choices={choices} label="Status" source="statuses" optionText="name" resettable alwaysOn/>
   </Filter>
 }
}

export const choices = [
    {id : 'ACTIVE', name: 'ACTIVE'},
    {id : 'INACTIVE', name: 'INACTIVE'}
];

export const WindowSequenceFilter = props => (
    <WindowSequenceFilterComponent {...props}  />
);

const BulkActionButtons = props => (
    <Fragment>
        <ShowBulkCopyButtonAdmin label="Copy" {...props} />
        <ShowBukDeleteButtonAdmin  label="Inactivate" {...props}/>
        <BulkExportButton {...props} />
    </Fragment>
);

const WindowSequenceViewButton = ({ record = {} }) => <ViewWindowMacrosDrawer title='View' id={record.id} />

const StatusIcons = ({ record }) => <span title={record.status === "NEEDS_ATTENTION" ? 'Needs Attention' : record.status === "ACTIVE" ? 'Active' : 'Inactive'}>{record.status === "NEEDS_ATTENTION" ? <WarningAmberOutlinedIcon style={{ color: 'orange'}}  /> : record.status === "ACTIVE" ? <CheckCircleOutlinedIcon style={{ color: 'green'}} /> : <CancelOutlinedIcon style={{ color: 'red'}}/>}</span>

const CreatedLocal = ({ record }) => {
  return <span>{record.createdAt != null && record.createdAt && `${Moment(Moment.utc(record.createdAt).toDate()).format('DD-MMM-YYYY')} `}</span>
};
const ModifiedAtAtLocal = ({ record }) => {
  return <span>{record.modifiedAt != null && record.modifiedAt && `${Moment(Moment.utc(record.modifiedAt).toDate()).tz(Moment.tz.guess()).format('DD-MMM-YYYY, h:mm:ss A z')} `}</span>
};

const WindowSequencePagination = props => <Pagination style={{ display: 'block' }} rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
export const WindowSequenceList = ({ permissions, ...props }) => (
  <Fragment>
    <List exporter={exporter} id="WindowSequenceList"  title="Window Sequences" bulkActionButtons={<BulkActionButtons  {...props} />} empty={false} actions={<WindowSequenceActions {...props}/>}
     filters={<WindowSequenceFilter />} {...props} sort={{ field: 'id', order: 'DESC' }} perPage={localStorage.getItem('windowSequence/perPage') ? localStorage.getItem('windowSequence/perPage') : 10} pagination={< WindowSequencePagination />} >
          <Datagrid rowClick={null} rowStyle={JobRowStyle}>
              <WindowSequenceViewButton source="view" sortable={false} />
              <ShowEditButtonAdmin label="Edit" source="edit" sortable={false} {...props}/>
              <TextField source="id" label="Id" />
              <TextField source="windowSequenceName" label="Name" />
              <ReferenceArrayField sortable={false} label="Windows" reference="lists/transactionalWindows" source="windowIdList">
                <SingleFieldList link="false">
                  <ChipField source="label"  size="small" />
                </SingleFieldList>
              </ReferenceArrayField>
              <StatusIcons source="status" label="Status" />
              <TextField source="createdBy.fullName" label="Created By" />
              <CreatedLocal source="createdAt" label="Created" />
              <TextField source="modifiedBy.fullName" label="Updated By" />
              <ModifiedAtAtLocal source="modifiedAt" label="Modified" />
          </Datagrid>
      </List>
  </Fragment>
);

import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import TAPUserMenu from './TAPUserMenu';
import { makeStyles } from '@material-ui/core/styles';
import * as Config from '../Config'

const styles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      height: '34px',
      backgroundColor: '#444',
      color: '#fff'
    }
};
const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    alignItems: 'center',
    height: '34px',
    backgroundColor: '#444 !important',
    color: '#fff',
    position: 'fixed',
    bottom: '0',
    width: '100%'
  },
  environment: {
    color: '#fff',
    height: '100%',
    alignItems: 'center',
    fontSize: '18px',
    fontWeight: '700',
    textTransform: 'uppercase',
    backgroundColor: '#3cac22 ',
    display: 'flex',
    paddingLeft: '10px',
    paddingRight: '10px',
    minWidth: '35px'
  },
  copyright: {
    textAlign: 'end',
    width: '100%',
    marginRight: '1%',
    fontSize: '13px',
    fontWeight: 'bold',
    fontFamily: 'Open Sans,Arial,Helvetica,sans-serif'
  }
}));
//<Toolbar>
  // <Typography variant="title" id="react-admin-title" />
//</Toolbar>
function ShowEnvironmment(props) {
  const classes = useStyles();
  const isNotProd = (process.env.REACT_APP_ENV !== 'PROD');
  var rlURL = ''
  switch (process.env.REACT_APP_ENV) {
    case 'LOCAL':
      rlURL = 'https://admin-int.rightsline.com/'
      break;
    case 'DEV':
      rlURL = 'https://beta-int.rightsline.com/'
      break;
    case 'QA':
        rlURL = 'https://beta-staging.rightsline.com/'
        break;
    default:
      rlURL = 'https://beta.rightsline.com/'
  }
  return <div className={classes.footer}><span className={classes.environment}><a rel="noopener noreferrer" href={rlURL} target="_blank"><img style={{ height: '25px', float: 'left', paddingRight: '8px' }} src={require('../images/rl-favicon.png')} alt='Rightsline'/></a>{isNotProd && process.env.REACT_APP_ENV}</span>
      <div className={classes.copyright}>©{new Date().getFullYear()} Disney Entertainment & ESPN Technology {Config.VERSION_NUMBER}</div>
  </div>
}

const TAPAppBar = withStyles(styles)(({ classes, ...props }) => (
    <AppBar {...props} userMenu={<TAPUserMenu />}>
        <Typography
            variant="subtitle1"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
        />
      <img style={{ height: "43px", borderRadius: "12px" }} src={require('../images/tap_full_logo.png')} alt='Disney Logo'  className="DisneyLogo" />
        <span className={classes.spacer} />
        <ShowEnvironmment classes={styles} />
    </AppBar>
));

export default TAPAppBar;

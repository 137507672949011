import * as React from 'react';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import PlayIcon from '@mui/icons-material/PlayCircleOutline';
import { Button, useRefresh} from 'react-admin';
import { confirmAlert } from 'react-confirm-alert';

class CheckForUpdatesButton extends Component {
  handleClick = () => {
    confirmAlert({
      title: 'Check For Updates',
      message: 'Are you sure you wish to check for updates on the licensing job?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            var jobId = this.props.id
            const token = localStorage.getItem('tap-token');
            const requestOptions = {
               method: 'POST',
               headers: { 'Content-Type': 'application/json', Authorization: token},
               body: [jobId]
            };
            fetch(process.env.REACT_APP_API_URL +  '/licensing/checkForUpdates', requestOptions).then(response => response.json())
            .then(body => {
              if(body === true){
                var successMessage = <div><div className="toastTitle">Success</div><div className="toastMainMessage">Job running</div></div>
                toast.success(successMessage, {
                  position: toast.POSITION.BOTTOM_CENTER,
                  closeButton: true,
                  autoClose: 5000
                });
                document.getElementById('REFRESH').click()
              }else{
                var errorMessage = <div><div className="toastTitle">Error</div><div className="toastMainMessage">{body.message}</div></div>
                toast.error(errorMessage, {
                  position: toast.POSITION.BOTTOM_CENTER,
                  closeButton: true,
                  autoClose: 5000
                });
              }
            }).catch(({ status, statusText}) => {
                console.log("Caught status status ", status);
                console.log("Caught status statusText ", statusText);
            })
          }
        },
        {
          label: 'No',
          onClick: () => console.log("User cancelled checking for updates on the licensing job")
        }
      ]
    });
  }
      render() {
        function Refresh() {
          const refresh = useRefresh();
          return (
            <>
              <Button id='REFRESH' style={{ display: "none" }} onClick={() => refresh()} />
            </>
          )
        }
        return (
            <Fragment>
            <div id="checkForUpdatesJobButton" style={{ float: 'right', marginLeft: 'auto' }} >
              <Button color="primary" style={{ float: 'center', marginLeft: 'center' }} label="Check For Updates" onClick={this.handleClick} {...this.props} ><PlayIcon /></Button>
              <Refresh/>
            </div>
            </Fragment>
            );
      }
}

const CheckForUpdates = connect()(CheckForUpdatesButton);
export default CheckForUpdates

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { Button } from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import ViewIcon from '@material-ui/icons/RemoveRedEye';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextFieldCore from '@material-ui/core/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Moment from 'moment-timezone';

class ViewRateCardDrawer extends Component {
    state = {
      showPanel: false,
      message: undefined,
      errors: [],
      data: {},
      allHistories: [],
      currentRateCard: null,
      currentWindowSeq: null,
      currentWindowList: [],
      currentTiersAliases: [],
      history: null,
      windowSequenceInHistory: null,
      windowsListInHistory: [],
      tiersAliasesInHistory: []
    };
    changeSelectedHistoryToView = (event, inputValue) => {
      const { currentRateCard, currentWindowSeq, currentWindowList, currentTiersAliases } = this.state;
      if (inputValue != null) {
        var historicalWindowSeq = null
        var historicalWindowList = []
        var historicalTiersAliases = []
        historicalWindowSeq = inputValue.windowSequence
        inputValue.windows.map(window => {
          return historicalWindowList.push(<ListItem className="window-Header" key={`window-${window.id.tapWindowId}`}>
            <ListItemText primary={<span>Name: {window.window.windowName}
            <br/>Duration - Unit: {window.window.windowDuration} - {window.window.windowDurationUnit}<br/></span>}
            secondary={<span>{window.percentage}%</span>} />
          </ListItem>)
        })
        var windowsListObjectHistory = []
        inputValue.windows.forEach(item => {
            var window = {windowId: item.id.tapWindowId, windowName: item.window.windowName}
            windowsListObjectHistory.push(window)
        });
        var rows = []
        inputValue.tierAliases.forEach(tier => {
          var id = 0
          tier.tiers.forEach(item =>{
            var window = windowsListObjectHistory.filter(it => item.id.tapWindowId === it.windowId)
              var row = {id: id, formatName: tier.tierAliasName, format: tier.tierAliasFormat,tier: item.tierName, price:item.price, windowName: window && window.length > 0 ? window[0].windowName : ''}
              rows.push(row)
              id++
          });
        })
        rows.map(row => {
          return historicalTiersAliases.push(<ListItem className="Tier-Header" key={`tiers-${row.id}-${row.tier}-`}>
            <ListItemText style={{ alignItems: 'top' }} primary={<span>{row.formatName}<br/>Format:{row.format}<br/>{row.windowName}<br/>Tier: {row.tier}<br/>Price: {row.price}<br/></span>} />
          </ListItem>)
        })
        this.setState({
          history: inputValue,
          windowSequenceInHistory: historicalWindowSeq,
          windowsListInHistory: historicalWindowList,
          tiersAliasesInHistory: historicalTiersAliases
        })
      } else {
        this.setState({
          data: currentRateCard,
          windowSequence: currentWindowSeq,
          windowsList: currentWindowList,
          tierAliases: currentTiersAliases,
          history: null,
          windowSequenceInHistory: null,
          windowsListInHistory: [],
          tiersAliasesInHistory: []
        })
      }
    };
    fetchHistory = async (rateCardId) => {
      const token = localStorage.getItem('tap-token');
      const requestOptions = {
         method: 'GET',
         headers: { 'Content-Type': 'application/json', Authorization: token},
      };
      return await fetch(process.env.REACT_APP_API_URL + '/rateCard/' + rateCardId + '/history', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        responseData.forEach(history => {
          console.log("history ", history)
          history["windows"].sort(function (a, b) {
              return a.id.windowId - b.id.windowId;
          });
          history["tierAliases"].sort(function (a, b) {
              return a.sortOrder - b.sortOrder;
          });
          history["tierAliases"].forEach(tierAlias => {
            tierAlias.tiers.sort(function (a, b) {
              return a.sortOrder - b.sortOrder;
            })
          })
        })
        return responseData
      }).catch(({ status, statusText}) => {
        console.log("Caught status status ", status);
        console.log("Caught status statusText ", statusText);
        return []
      });
    };
    handleClick = async () => {
      var errors = []
      const token = localStorage.getItem('tap-token');
      const requestOptions = {
         method: 'GET',
         headers: { 'Content-Type': 'application/json', Authorization: token},
      };
      if (this.props.id) {
        const allHistories = await this.fetchHistory(this.props.id);
        return await fetch(process.env.REACT_APP_API_URL + '/rateCard/' + this.props.id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          var windowSequence = responseData.windowSequence
          var windows = responseData.windows
          responseData["windows"].sort(function (a, b) {
              return a.id.windowId - b.id.windowId;
          });
          responseData["tierAliases"].sort(function (a, b) {
              return a.sortOrder - b.sortOrder;
          });
          var windowsListObject = []
          windows.forEach(item => {
              var window = {windowId: item.id.tapWindowId, windowName: item.window.windowName}
              windowsListObject.push(window)
          });
          var tierAliases = []
          var windowsList = []
          windows.map(window => {
            return windowsList.push(<ListItem className="window-Header" key={`window-${window.id.tapWindowId}`}>
              <ListItemText primary={<span>Name: {window.window.windowName}
              <br/>Duration - Unit: {window.window.windowDuration} - {window.window.windowDurationUnit}<br/></span>}
              secondary={<span>{window.percentage}%</span>} />
            </ListItem>)
          })
          var rows = []
          responseData.tierAliases.forEach(tier => {
            var id = 0
            tier.tiers.forEach(item =>{
              var window = windowsListObject.filter(it => item.id.tapWindowId === it.windowId)
                var row = {id: id, formatName: tier.tierAliasName, format: tier.tierAliasFormat,tier: item.tierName, price:item.price, windowName: window && window.length > 0 ? window[0].windowName : ''}
                rows.push(row)
                id++
            });
          })
          rows.map(row => {
            return tierAliases.push(<ListItem className="Tier-Header" key={`tiers-${row.id}-${row.tier}-`}>
              <ListItemText style={{ alignItems: 'top' }} primary={<span>{row.formatName}<br/>Format:{row.format}<br/>{row.windowName}<br/>Tier: {row.tier}<br/>Price: {row.price}<br/></span>} />
            </ListItem>)
          })
          this.setState({
            data: responseData,
            windowSequence: windowSequence,
            tierAliases: tierAliases,
            windowsList : windowsList,
            errors : errors,
            showPanel: true,
            allHistories: allHistories,
            currentRateCard: responseData,
            currentWindowSeq: windowSequence,
            currentWindowList: windowsList,
            currentTiersAliases: tierAliases
          })
        }).catch(({ status, statusText}) => {
          console.log("Caught status status ", status);
          console.log("Caught status statusText ", statusText);
        });
      } else {
        this.setState({
          showPanel: true,
          errors : []
        });
      }
    };
    handleCloseClick = () => {
        this.setState({ showPanel: false });
    };
    render() {
        const { showPanel, data, errors, windowSequence, tierAliases, windowsList, allHistories, history,windowSequenceInHistory, tiersAliasesInHistory, windowsListInHistory} = this.state;
        return (
            <Fragment>
                <div id="ViewIcon" className="ViewIcon">
                  <Button onClick={this.handleClick}><ViewIcon/></Button>
                </div>
                <Drawer
                    anchor="right"
                    open={showPanel}
                    onClose={this.handleCloseClick}
                >
                    <div style={{ marginLeft: '30px', marginRight: '40px', marginTop: '20px' }}>
                      <Fragment>
                            <Typography style={{borderBottom: '2px solid black', borderBottomColor:"#000099", fontSize: "28px ", minHeight: "80px"}} variant="subtitle1" color="inherit" >Rate Card View
                            <Autocomplete
                               style={{ width: "50%", float: "right", paddingTop: "1%", marginBottom: '1%'}}
                               autoHighlight
                               onChange={this.changeSelectedHistoryToView}
                               isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
                               getOptionLabel={(option) => Moment(Moment.utc(option.historyCreatedAt).toDate()).tz(Moment.tz.guess()).format('MM/DD/YYYY, h:mm:ss A z') + ' | ' + option.historyCreatedBy.username }
                               options={allHistories}
                               renderInput={(params) => (
                                 <TextFieldCore
                                   {...params}
                                   label="History"
                                   onChange={this.changeSelectedHistoryToView}
                                   variant="outlined"
                                   InputProps={{
                                     ...params.InputProps,
                                     endAdornment: (
                                       <React.Fragment>
                                         {params.InputProps.endAdornment}
                                       </React.Fragment>
                                     ),
                                   }}
                                 />
                               )}
                            />
                            </Typography>
                            <div style={{ float: "left", width: "48%"}} >
                            <div style={{ marginBottom: '10px' }}/>
                            <Typography style={{ fontSize: "17px ", padding: "25px"}} variant="inherit" color="inherit" >{data && data.id} - {data && data.rateCardName} </Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            <Typography style={{ fontSize: "17px ", padding: "25px"}} variant="inherit" color="inherit" >Status: {data && data.status}</Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            <Typography style={{ fontSize: "17px ", padding: "25px"}} variant="inherit" color="inherit" >Currency: {data && data.currency}</Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            <Typography style={{ fontSize: "17px ", padding: "25px"}} variant="inherit" color="inherit" >Price Type: {data && data.priceType}</Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            <Typography style={{ fontSize: "17px ", padding: "25px"}} variant="inherit" color="inherit" >Rev Share Type: {data && data.revShareType}</Typography>
                            <div style={{ marginBottom: '20px' }}/>
                            <Typography style={{ fontSize: "20px "}} variant="subtitle2" color="inherit" >Window Sequence</Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            <Typography style={{ fontSize: "16px ", padding: "25px"}} variant="inherit" color="inherit" >Name: {windowSequence && windowSequence.windowSequenceName}</Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            <Typography style={{ fontSize: "16px ", padding: "25px"}} variant="inherit" color="inherit" >Status: {windowSequence && windowSequence.status}</Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            {windowSequence && windowSequence.length === 0 && <span>No window sequence</span>}
                            <div style={{ marginBottom: '20px' }}/>
                            <Typography style={{ fontSize: "20px "}} variant="subtitle2" color="inherit" >Window</Typography>
                            <div style={{ marginBottom: '10px' }}>
                              {windowsList && windowsList.map(window => window)}
                            </div>
                            {windowsList && windowsList.length === 0 && <span>No windows</span>}
                            <div style={{ marginBottom: '20px' }}/>
                            <Typography style={{ fontSize: "20px "}} variant="subtitle2" color="inherit" >Tiers -> Prices</Typography>
                            <div style={{ marginBottom: '10px' }}>
                              {tierAliases && tierAliases.map(tier => tier)}
                            </div>
                            {tierAliases && tierAliases.length === 0 && <span>No tiers -> No Prices</span>}
                            <div style={{ marginBottom: '50px' }}/>
                            </div>
                            {history != null && <div style={{ borderLeft: '2px solid black', borderLeftColor:"#000099",  paddingLeft: '1%', float: "left", width: "48%"}} >
                            <Typography style={{ fontSize: "17px ", padding: "25px"}} variant="inherit" color="inherit" >{history && history.rateCardId} - {history && history.rateCardName} </Typography>                            
                            <div style={{ marginBottom: '10px' }}/>
                            <Typography style={{ fontSize: "17px ", padding: "25px"}} variant="inherit" color="inherit" >Status: {history && history.status}</Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            <Typography style={{ fontSize: "17px ", padding: "25px"}} variant="inherit" color="inherit" >Currency: {history && history.currency}</Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            <Typography style={{ fontSize: "17px ", padding: "25px"}} variant="inherit" color="inherit" >Price Type: {history && history.priceType}</Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            <Typography style={{ fontSize: "17px ", padding: "25px"}} variant="inherit" color="inherit" >Rev Share Type: {history && history.revShareType}</Typography>
                            <div style={{ marginBottom: '20px' }}/>
                            <Typography style={{ fontSize: "20px "}} variant="subtitle2" color="inherit" >Window Sequence</Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            <Typography style={{ fontSize: "16px ", padding: "25px"}} variant="inherit" color="inherit" >Name: {windowSequenceInHistory && windowSequenceInHistory.windowSequenceName}</Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            <Typography style={{ fontSize: "16px ", padding: "25px"}} variant="inherit" color="inherit" >Status: {windowSequenceInHistory && windowSequenceInHistory.status}</Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            {windowSequenceInHistory && windowSequenceInHistory.length === 0 && <span>No window sequence</span>}
                            <div style={{ marginBottom: '20px' }}/>
                            <Typography style={{ fontSize: "20px "}} variant="subtitle2" color="inherit" >Window</Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            {windowsListInHistory && windowsListInHistory.map(window => window)}
                            {windowsListInHistory && windowsListInHistory.length === 0 && <span>No windows</span>}
                            <div style={{ marginBottom: '20px' }}/>
                            <Typography style={{ fontSize: "20px "}} variant="subtitle2" color="inherit" >Tiers -> Prices</Typography>
                            <div style={{ marginBottom: '10px' }}/>
                            {tiersAliasesInHistory && tiersAliasesInHistory.map(tier => tier)}
                            {tiersAliasesInHistory && tiersAliasesInHistory.length === 0 && <span>No tiers -> No Prices</span>}
                            <div style={{ marginBottom: '50px' }}/>
                            </div>}
                            {errors}
                            <div style={{ zIndex: 1, textAlign: 'center', position: 'fixed', bottom: '0', float: 'left', right: '0' }}>
                              <Button
                                style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '20px'}}
                                label="Close"
                                onClick={this.handleCloseClick}>
                                  <IconCancel />
                             </Button>
                          </div>
                      </Fragment>
                    </div>
                </Drawer>
            </Fragment>
        );
    }
}
const RateCardView = connect()(ViewRateCardDrawer);
export default RateCardView

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useRefreshWhenVisible } from 'ra-core';
import { LinearProgress } from 'react-admin'; // ListGuesser

const LoadingIndicator = props => {
    useRefreshWhenVisible();
    const loading = useSelector(state => state.admin.loading > 0);
    return loading ? (
        <LinearProgress style={{position: "fixed", height: "5px", top: "55px", width: "100%", margin: "0" }}/>
    ) : <div style={{width: "100%", height: "5px"}}></div>
};

LoadingIndicator.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    width: PropTypes.string,
};

export default LoadingIndicator;

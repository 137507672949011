import * as React from 'react';
import { Fragment, useState } from 'react';
import { Delete } from '@material-ui/icons';
import {
    Button,
    Confirm,
    useRefresh,
    useNotify,
    useUnselectAll,
} from 'react-admin';
import { toast } from 'react-toastify';

const BulkDeleteButton = ({ selectedIds }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();

    const deleteMany = function() {
        setLoading(true)
        const token = localStorage.getItem('tap-token');
        const requestOptions = {
           method: 'DELETE',
           headers: { 'Content-Type': 'application/json', Authorization: token},
           body: JSON.stringify(selectedIds)
        };
        fetch(process.env.REACT_APP_API_URL + '/windowSequence/deleteBulk', requestOptions)
        .then(responseData => {
             if (responseData.error) {
                toast.dismiss()
                var errorMessage = <div><div className="toastTitle">Error</div><div className="toastMainMessage">{responseData && responseData.error && responseData.error.message ? responseData.error.message : "Internal Server Error"}</div></div>
                toast.error(errorMessage, {
                  position: toast.POSITION.BOTTOM_CENTER,
                  closeButton: true,
                  autoClose: 5000
                });
            } else if(responseData.status === 400){
                notify('Window Sequence(s) could not inactivate', { type: 'warning' });
            } else{
                notify('Success, Window Sequence(s) Inactivated');
            }
          refresh();
          unselectAll('windowSequence');
          setLoading(false)
        }).catch(error  => {
          console.log("error ", error)
          notify('Error: windows not deleted', { type: 'warning' })
          setLoading(false)
        });
    };
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        deleteMany();
        setOpen(false);
    };

    return (
        <Fragment>
            <Button style={{color: '#F44336'}} label="Inactivate" onClick={handleClick}><Delete/></Button>
            <Confirm
                isOpen={open}
                loading={loading}
                title={"Inactivate Window" + (selectedIds.toString().indexOf(',') > 0 ? "s" : "")}
                content={"Are you sure you want to inactivate " + (selectedIds.toString().indexOf(',') > 0 ? "the selected windows?" : "this window?")}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
}

export default BulkDeleteButton;

import React from 'react';
import { useEffect } from 'react';
import { Layout, Sidebar, Notification } from 'react-admin';
import TAPAppBar from './TAPAppBar';
import TAPMenu from './TAPMenu';

const MyNotification = props => <Notification {...props} autoHideDuration={5000} />;

const TAPLayout = (props: any) => {
    useEffect(() => {
    });

    return (
        <Layout
            {...props}
            appBar={TAPAppBar}
            sidebar={Sidebar}
            notification={MyNotification}
            menu={TAPMenu}
        />
    );
};

export default TAPLayout

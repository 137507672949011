import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';

const JobRowStyle = (record, index, defaultStyle = {}) => {
    if (record) {
      if (record.status === 'ACTIVE')
          return {
              ...defaultStyle,
              borderLeftColor: green[500],
              borderLeftWidth: 5,
              borderLeftStyle: 'solid',
          };
      if (record.status === 'NEEDS_ATTENTION' || record.countOffersUpdatedAndActive > 0 )
          return {
              ...defaultStyle,
              borderLeftColor: orange[500],
              borderLeftWidth: 5,
              borderLeftStyle: 'solid',
          };
      if (record.status === 'ERROR' || record.status === 'INACTIVE' || record.status === 'INACTIVATED' || record.status === 'ABORTED' || record.status === 'INVALID' || record.countOffersInvalid > 0)
          return {
              ...defaultStyle,
              borderLeftColor: red[500],
              borderLeftWidth: 5,
              borderLeftStyle: 'solid',
          };
      if (record.status === 'COMPLETE')
          return {
              ...defaultStyle,
              borderLeftColor: green[500],
              borderLeftWidth: 5,
              borderLeftStyle: 'solid',
          };
      if (record.status === 'READY' || record.status === 'CREATED')
          return {
              ...defaultStyle,
              borderLeftColor: green[500],
              borderLeftWidth: 5,
              borderLeftStyle: 'ridge',
          };
      if (record.status === 'STARTING' || record.status === 'RUNNING' || record.status === 'VALIDATING')
              return {
                  ...defaultStyle,
                  borderLeftColor: blue[500],
                  borderLeftWidth: 5,
                  borderLeftStyle: 'ridge',
              };
      if (record.status === 'FAILED' || record.status === 'INVALID')
          return {
              ...defaultStyle,
              borderLeftColor: red[500],
              borderLeftWidth: 5,
              borderLeftStyle: 'solid',
          };
    }
    return defaultStyle;
};
export default JobRowStyle;

// in src/screens/Dashboard.js
import React, { Component }  from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { Responsive } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import '../index.css';
import { connect } from 'react-redux';
import RecentlyUpdatedPMX from '../components/RecentlyUpdatedPMX';
import LicensingJobStatusForUser from '../components/LicensingJobStatusForUser';
import simpleRestProvider from 'ra-data-simple-rest';
import { fetchUtils } from 'react-admin';

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginLeft: '1em', marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
};

const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  return fetchUtils.fetchJson(url, options);
}


class Dashboard extends Component {
    state = {};
    componentDidMount() {
        this.fetchData();
    }
    fetchData() {
        console.log("Inside dashboard fetchData ")
        console.log("Inside dashboard token ", localStorage.getItem('token'))
        console.log("Inside dashboard permissions ", localStorage.getItem('permissions'))

        if (localStorage.getItem('permissions')) {
          this.fetchRecentUpdatedPMX();
          this.fetchMyLicensingJobsWithStatusCounts()
        }
    }
    async fetchRecentUpdatedPMX() {
      console.log("Inside fetchRecentUpdatedPMX ")
        this.setState({
          isLoadingRecentlyUpdatedPMX : true
        });
        const token = localStorage.getItem('tap-token');
        const requestOptions = {
           method: 'GET',
           headers: { 'Content-Type': 'application/json', Authorization: token},
        };
        await fetch(process.env.REACT_APP_API_URL + '/pmxCuratedDatesAndPrices/recentlyChanged', requestOptions)
          .then(response => response.json())
          .then(recentlyChangedResults => {
            console.log("recentlyChangedResults ", recentlyChangedResults)
            if (recentlyChangedResults.status && recentlyChangedResults.status === 401) {
              const dataProvider = simpleRestProvider(process.env.REACT_APP_API_URL, httpClient)
              return dataProvider.getMany('users/logout', {
                  id: 0
                }).then((test) => {
                    localStorage.clear()
                }).finally(() => {
                  window.history.pushState({}, document.title, "/#/");
                  window.location = "/#"
                  window.location.reload()
                })
            }
            this.setState({
              recentlyUpdatedPMX: recentlyChangedResults,
              isLoadingRecentlyUpdatedPMX: false
            });
          }).catch(ex => {
           console.log("Handled exception loading recently changed pmx results ", ex)
          });
    }
    async fetchMyLicensingJobsWithStatusCounts() {
        this.setState({
          isLoadingMyLicensingJobsWithStatusCounts : true
        });
        const token = localStorage.getItem('tap-token');
        const requestOptions = {
           method: 'GET',
           headers: { 'Content-Type': 'application/json', Authorization: token},
        };
        await fetch(process.env.REACT_APP_API_URL + '/licensing/licensingJobStatusForUser', requestOptions)
          .then(response => response.json())
          .then(myLicensingJobsWithStatusCounts => {
            console.log("myLicensingJobsWithStatusCounts ", myLicensingJobsWithStatusCounts)
            console.log("pendingJobs ", myLicensingJobsWithStatusCounts['Pending'])
            console.log("Updated/Modified ", myLicensingJobsWithStatusCounts['Updated/Modified'])
            console.log("Updated and Active ", myLicensingJobsWithStatusCounts['Updated and Active'])
            console.log("Duplicate ", myLicensingJobsWithStatusCounts['Duplicate'])
            console.log("Needs Attention ", myLicensingJobsWithStatusCounts['Needs Attention'])
            this.setState({
              pendingJobs: myLicensingJobsWithStatusCounts['Pending'],
              systemCreatedJobs: myLicensingJobsWithStatusCounts['System Created'],
              updatedModifiedJobs: myLicensingJobsWithStatusCounts['Updated/Modified'],
              updatedAndActiveJobs: myLicensingJobsWithStatusCounts['Updated and Active'],
              duplicateJobs: myLicensingJobsWithStatusCounts['Duplicate'],
              needsAttentionJobs: myLicensingJobsWithStatusCounts['Needs Attention'],
              isLoadingMyLicensingJobsWithStatusCounts: false
            });
          }).catch(ex => {
           console.log("Handled exception loading my licensing jobs with status counts ", ex)
          });
    }

    render() {
        const {
              isLoadingRecentlyUpdatedPMX,
              isLoadingMyLicensingJobsWithStatusCounts,
              recentlyUpdatedPMX,
              pendingJobs,
              updatedModifiedJobs,
              updatedAndActiveJobs,
              duplicateJobs,
              needsAttentionJobs,
              systemCreatedJobs
        } = this.state;
        return (
            <Responsive
                small={
                    <div style={styles.flexColumn}>
                        <div style={styles.singleCol}>
                          <Card className="DisneyCard">
                              <CardHeader title="Welcome to Disney's TAP: Transactional Automation Planning" className="DisneyWhiteBG" />
                              <CardContent className="DisneyWhiteBG">
                                <Typography color="textSecondary" gutterBottom style={{ fontSize: "24px "}}>
                                  Disney's TAP admin application allows you to access custom functions that directly interface with Rightsline
                                </Typography>
                            </CardContent>
                          </Card>
                        </div>
                        <div style={styles.singleCol}>
                          <LicensingJobStatusForUser isLoading={isLoadingMyLicensingJobsWithStatusCounts} pendingJobs={pendingJobs} updatedModifiedJobs={updatedModifiedJobs} updatedAndActiveJobs={updatedAndActiveJobs} duplicateJobs={duplicateJobs} needsAttentionJobs={needsAttentionJobs} />
                        </div>
                        <div style={styles.singleCol}>
                          <RecentlyUpdatedPMX isLoading={isLoadingRecentlyUpdatedPMX} recentlyUpdatedPMX={recentlyUpdatedPMX} />
                        </div>
                    </div>
                }
                medium={
                    <div style={styles.flexCol}>
                      <div style={styles.flex}>
                        <div style={{ marginBottom: '2em',  width: '98%' }}>
                          <Card className="DisneyCard">
                              <CardHeader title="Welcome to Disney's TAP: Transactional Automation Planning" className="DisneyWhiteBG" />
                              <CardContent className="DisneyWhiteBG">
                                <Typography color="textSecondary" gutterBottom style={{ fontSize: "24px "}}>
                                  Disney's TAP admin application allows you to access custom functions that directly interface with Rightsline
                                </Typography>
                            </CardContent>
                          </Card>
                        </div>
                      </div>
                      <div style={styles.flex}>
                        <div style={styles.leftCol}>
                          <RecentlyUpdatedPMX isLoading={isLoadingRecentlyUpdatedPMX} recentlyUpdatedPMX={recentlyUpdatedPMX} />
                        </div>
                        <div style={styles.rightCol}>
                          <LicensingJobStatusForUser isLoading={isLoadingMyLicensingJobsWithStatusCounts} pendingJobs={pendingJobs} updatedModifiedJobs={updatedModifiedJobs} updatedAndActiveJobs={updatedAndActiveJobs} duplicateJobs={duplicateJobs} needsAttentionJobs={needsAttentionJobs} systemCreatedJobs={systemCreatedJobs} />
                        </div>
                      </div>
                    </div>
                }
            />
        );
    }
}

// <RecentContacts isLoading={isLoadingRecentContacts} contacts={recentContacts} />
// <RecentlyUpdatedCatalogItems isLoading={isLoadingRecentUpdatedCatalogItems} recentlyUpdatedCatalogItems={recentUpdatedCatalogItems} />

const mapStateToProps = state => ({
    recentlyUpdatedPMX: state.admin.ui.recentlyUpdatedPMX,
});

export default connect(mapStateToProps)(Dashboard);

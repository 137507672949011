import React, {Fragment} from 'react';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ContactsIcon from '@material-ui/icons/ContactMailTwoTone';
import { Button } from 'react-admin';
import CardIcon from './CardIcon';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';

const styles = {
    main: {
        flex: '1',
        marginRight: '1em',
        marginTop: 20,
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 16,
        minHeight: 52,
    },
    title: {
        textAlign: 'left',
        fontSize: 24,
    },
};

const LicensingJobStatusForUser = withStyles(styles)(({ pendingJobs, updatedModifiedJobs, updatedAndActiveJobs, duplicateJobs, needsAttentionJobs, systemCreatedJobs, translate, classes, isLoading }) => (
    <div className={classes.main}>
        <Card className={classes.card}>
            <CardIcon Icon={ContactsIcon} bgColor={'green'} style={{height: '54px', width:'54px', float: 'left'}}/>
            <Typography className={classes.title} color="textSecondary">
              Status of My Licensing Job Offers
            </Typography>
            {isLoading === true ? <CircularProgress /> : <div>
              <Divider style={{ marginTop: '1em', marginBottom: '1em', width: '98%' }} />
              <Typography className={classes.title} color="textSecondary" style={{ color: pendingJobs && pendingJobs.length > 0 ? 'green' : 'rgba(0, 0, 0, 0.54)' }}>
                Pending
              </Typography>
              <List>
                  { pendingJobs && pendingJobs.map(record => (
                    <Fragment key={`pending-${record.licensingJob.id}`}>
                      <ListItem
                          key={`pending-${record.licensingJob.id}`}
                      >
                          <ListItemText
                              primary={`${record.licensingJob.id} - ${record.licensingJob.description} (${record.offerCount}) `}
                              className={classes.listItemText}
                          />
                          <Button
                            className={classes.button}
                            onClick={() => {window.location=`#/licensing/offerTable/${record.licensingJob.id}`}}
                            label="View Offers"
                          />
                      </ListItem>
                    </Fragment>
                  ))}
              </List>
              <Typography className={classes.title} color="textSecondary" style={{ color: systemCreatedJobs && systemCreatedJobs.length > 0 ? 'red' : 'rgba(0, 0, 0, 0.54)' }}>
                System Created Jobs
              </Typography>
              <List>
                  { systemCreatedJobs && systemCreatedJobs.map(record => (
                    <Fragment key={`pending-${record.licensingJob.id}`}>
                      <ListItem
                          key={`pending-${record.licensingJob.id}`}
                      >
                          <ListItemText
                              primary={`${record.licensingJob.id} - ${record.licensingJob.description} (${record.offerCount}) `}
                              className={classes.listItemText}
                          />
                          <Button
                            className={classes.button}
                            onClick={() => {window.location=`#/licensing/offerTable/${record.licensingJob.id}`}}
                            label="View Offers"
                          />
                      </ListItem>
                    </Fragment>
                  ))}
              </List>
              <Typography className={classes.title} color="textSecondary" style={{ color: updatedModifiedJobs && updatedModifiedJobs.length > 0 ? 'red' : 'rgba(0, 0, 0, 0.54)' }}>
                Updated/Modified - System Updated, User Modified
              </Typography>
              <List>
                  { updatedModifiedJobs && updatedModifiedJobs.map(record => (
                    <Fragment key={`updatedModifiedJobs-${record.licensingJob.id}`}>
                      <ListItem
                          key={`updatedModifiedJobs-${record.licensingJob.id}`}
                      >
                          <ListItemText
                              primary={`${record.licensingJob.id} - ${record.licensingJob.description} (${record.offerCount}) `}
                              className={classes.listItemText}
                          />
                          <Button
                            className={classes.button}
                            onClick={() => {window.location=`#/licensing/offerTable/${record.licensingJob.id}`}}
                            label="View Offers"
                          />
                      </ListItem>
                    </Fragment>
                  ))}
              </List>
              <Typography className={classes.title} color="textSecondary" style={{ color: updatedAndActiveJobs && updatedAndActiveJobs.length > 0 ? 'red' : 'rgba(0, 0, 0, 0.54)' }}>
                Active Updated/Modified
              </Typography>
              <List>
                  { updatedAndActiveJobs && updatedAndActiveJobs.map(record => (
                    <Fragment key={`updatedAndActiveJobs-${record.licensingJob.id}`}>
                      <ListItem
                          key={`updatedAndActiveJobs-${record.licensingJob.id}`}
                      >
                          <ListItemText
                              primary={`${record.licensingJob.id} - ${record.licensingJob.description} (${record.offerCount}) `}
                              className={classes.listItemText}
                          />
                          <Button
                            className={classes.button}
                            onClick={() => {window.location=`#/licensing/offerTable/${record.licensingJob.id}`}}
                            label="View Offers"
                          />
                      </ListItem>
                    </Fragment>
                  ))}
              </List>
              <Typography className={classes.title} color="textSecondary" style={{ color: duplicateJobs && duplicateJobs.length > 0 ? 'red' : 'rgba(0, 0, 0, 0.54)' }}>
                Duplicate
              </Typography>
              <List>
                  { duplicateJobs && duplicateJobs.map(record => (
                    <Fragment key={`duplicateJobs-${record.licensingJob.id}`}>
                      <ListItem
                          key={`duplicateJobs-${record.licensingJob.id}`}
                      >
                          <ListItemText
                              primary={`${record.licensingJob.id} - ${record.licensingJob.description} (${record.offerCount}) `}
                              className={classes.listItemText}
                          />
                          <Button
                            className={classes.button}
                            onClick={() => {window.location=`#/licensing/offerTable/${record.licensingJob.id}`}}
                            label="View Offers"
                          />
                      </ListItem>
                    </Fragment>
                  ))}
              </List>
              <Typography className={classes.title} color="textSecondary" style={{ color: needsAttentionJobs && needsAttentionJobs.length > 0 ? 'red' : 'rgba(0, 0, 0, 0.54)' }}>
                Needs Attention - Blank Deal Fields, Inactive Deal, Info Restrictions, Missing Pricing, Rights Issue, To Delete
              </Typography>
              <List>
                  { needsAttentionJobs && needsAttentionJobs.map(record => (
                    <Fragment key={`needsAttentionJobs-${record.licensingJob.id}`}>
                      <ListItem
                          key={`needsAttentionJobs-${record.licensingJob.id}`}
                      >
                          <ListItemText
                              primary={`${record.licensingJob.id} - ${record.licensingJob.description} (${record.offerCount}) `}
                              className={classes.listItemText}
                          />
                          <Button
                            className={classes.button}
                            onClick={() => {window.location=`#/licensing/offerTable/${record.licensingJob.id}`}}
                            label="View Offers"
                          />
                      </ListItem>
                    </Fragment>
                  ))}
              </List>
            </div> }
        </Card>
    </div>
));


const mapStateToProps = state => ({
    //recentlyUpdatedPMX: state.admin.ui.recentlyUpdatedPMX,
});

export default connect(mapStateToProps)(LicensingJobStatusForUser);

// in PostQuickPreviewButton.js
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { Button } from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import ViewIcon from '@material-ui/icons/RemoveRedEye';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextFieldCore from '@material-ui/core/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Moment from 'moment-timezone';

class ViewWindowMacrosDrawer extends Component {
    state = {
      showPanel: false,
      message: undefined,
      errors: [],
      data: {},
      windows: [],
      widowSequenceHistorySelected : "",
      allWindowHistories: [],
      currentWindowSequence: null,
      currentWindows: [],
      windowHistory: null,
      windowsInHistory: []
    };
    changeSelectedWindowHistoryToView = (event, inputValue) => {
      const { currentWindowSequence, currentWindows } = this.state;
      if (inputValue != null) {
        var historicalWindows = []
        inputValue.windows.map(window => {
          return historicalWindows.push(<ListItem className="Window-Header" key={`window-${window.id}`}>
            <ListItemText primary={<span>{window.windowName}</span>} secondary={<span>{window.windowDuration} {window.windowDurationUnit}</span>} />
          </ListItem>)
        })
        this.setState({
          windowHistory: inputValue,
          windowsInHistory: historicalWindows
        })
      } else {
        this.setState({
          data: currentWindowSequence,
          windows: currentWindows,
          windowHistory: null,
          windowsInHistory: []
        })
      }
    };
    fetchWindowHistory = async (windowSequenceId) => {
        const token = localStorage.getItem('tap-token');
        const requestOptions = {
           method: 'GET',
           headers: { 'Content-Type': 'application/json', Authorization: token},
        };
        return await fetch(process.env.REACT_APP_API_URL + '/windowSequence/' + windowSequenceId + '/history', requestOptions)
        .then(response => response.json())
        .then(responsData => {
          return responsData
        }).catch(({ status, statusText}) => {
          console.log("Caught status status ", status);
          console.log("Caught status statusText ", statusText);
          return []
        });
    };
    handleClick = async () => {
      console.log("Passed id into handleClick ", this.props.id);
      var errors = []
      const token = localStorage.getItem('tap-token');
      const requestOptions = {
         method: 'GET',
         headers: { 'Content-Type': 'application/json', Authorization: token},
      };
      if (this.props.id) {
        const allWindowHistories = await this.fetchWindowHistory(this.props.id);
        return await fetch(process.env.REACT_APP_API_URL + '/windowSequence/' + this.props.id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          console.log('allWindowHistories ', allWindowHistories)
          var windows = []
          responseData.windows.map(window => {
            return windows.push(<ListItem className="Window-Header" key={`window-${window.id}`}>
              <ListItemText primary={<span>{window.windowName}</span>} secondary={<span>{window.windowDuration} {window.windowDurationUnit}</span>} />
            </ListItem>)
          })
          this.setState({
            data: responseData,
            windows: windows,
            currentWindowSequence: responseData,
            currentWindows: windows,
            errors : errors,
            showPanel: true,
            allWindowHistories: allWindowHistories
          })
        }).catch(({ status, statusText, headers, body }) => {
          console.log("Caught status status ", status);
          console.log("Caught status statusText ", statusText);
        });
      } else {
        this.setState({
          showPanel: true,
          errors : []
        });
      }
    };
    handleCloseClick = () => {
        this.setState({ showPanel: false });
    };
    render() {
        const { showPanel, data, errors, windows, allWindowHistories, windowHistory, windowsInHistory} = this.state;        
        return (
            <Fragment>
                <div id="ViewIcon" className="ViewIcon">
                  <Button onClick={this.handleClick}><ViewIcon/></Button>
                </div>
                <Drawer
                    anchor="right"
                    open={showPanel}
                    onClose={this.handleCloseClick}
                >
                    <div style={{ marginLeft: '20px', marginRight: '20px' }}>
                      <Fragment>
                            <Typography style={{borderBottom: '2px solid black', borderBottomColor:"#000099", fontSize: "28px", minHeight: "80px"}} variant="subtitle1" color="inherit" >Window Sequence
                              <Autocomplete
                               style={{ width: "50%", float: "right", paddingTop: "1%", marginBottom: '1%'}}
                               autoHighlight
                               onChange={this.changeSelectedWindowHistoryToView}
                               isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
                               getOptionLabel={(option) => Moment(Moment.utc(option.historyCreatedAt).toDate()).tz(Moment.tz.guess()).format('DD-MMM-YYYY, h:mm:ss A z') + ' | ' + option.historyCreatedBy.username }
                               options={allWindowHistories}
                               renderInput={(params) => (
                                 <TextFieldCore
                                   {...params}
                                   label="History"
                                   onChange={this.changeSelectedWindowHistoryToView}
                                   variant="outlined"
                                   InputProps={{
                                     ...params.InputProps,
                                     endAdornment: (
                                       <React.Fragment>
                                         {params.InputProps.endAdornment}
                                       </React.Fragment>
                                     ),
                                   }}
                                 />
                               )}
                            />
                            </Typography>
                            <div style={{ float: "left", width: "48%"}} >
                            <div style={{ marginBottom: '10px' }}/>
                              <Typography style={{ fontSize: "18px "}} variant="inherit" color="inherit" >{data && data.windowSequenceName}</Typography>
                              <div style={{ marginBottom: '10px' }}/>
                              <Typography style={{ fontSize: "16px "}} variant="inherit" color="inherit" >{data && data.status}</Typography>
                              <div style={{ marginBottom: '10px' }}/>
                              <Typography style={{ fontSize: "18px "}} variant="subtitle2" color="inherit" >Windows</Typography>
                              <div style={{ marginBottom: '10px' }}/>
                              {windows && windows.length === 0 && <span>No Windows</span>}
                              {windows && windows.map(window => window)}
                            </div>
                            {windowHistory != null && <div style={{ borderLeft: '2px solid black', borderLeftColor:"#000099", paddingLeft: '1%', float: "left", width: "48%"}} >
                              <Typography style={{ fontSize: "18px "}} variant="inherit" color="inherit" >{windowHistory && windowHistory.windowSequenceName}</Typography>
                              <div style={{ marginBottom: '10px' }}/>
                              <Typography style={{ fontSize: "16px "}} variant="inherit" color="inherit" >{windowHistory && windowHistory.status}</Typography>
                              <div style={{ marginBottom: '10px' }}/>
                              <Typography style={{ fontSize: "18px "}} variant="subtitle2" color="inherit" >Windows</Typography>
                              <div style={{ marginBottom: '10px' }}/>
                              {windowsInHistory && windowsInHistory.length === 0 && <span>No Windows</span>}
                              {windowsInHistory && windowsInHistory.map(window => window)}
                            </div>}
                            <div style={{ width: '100%', marginBottom: '50px' }}/>
                            {errors}
                            <div style={{ zIndex: 1, textAlign: 'center', position: 'fixed', bottom: '0', float: 'left', right: '0' }}>
                              <Button
                                style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '20px'}}
                                label="Close"
                                onClick={this.handleCloseClick}>
                                  <IconCancel />
                             </Button>
                          </div>
                      </Fragment>
                    </div>
                </Drawer>
            </Fragment>
        );
    }
}
const ViewWindowMacros = connect()(ViewWindowMacrosDrawer);
export default ViewWindowMacros

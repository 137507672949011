// in src/theme/Theme.js
import { createTheme } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
//import pink from '@material-ui/core/colors/pink';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';

export const myTheme = createTheme({
    palette: {
        primary: indigo,
        secondary: blue,
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "Avenir Next",
            '-apple-system',
            'BlinkMacSystemFont',
            "Helvetica Neue",
            'Arial',
            'sans-serif',
        ].join(','),
    },
    overrides: {
        RaLogout: {
          icon: {
            color: "black",
          },
          menuItem: {
            color: "black !important",
          }
        },
        FormInput: {
          root: {
            width: '100% !important',
          }
        },
        MuiFormControl: {
          root: {
            width: '100% !important',
          }
        },
        MuiToolbar: {
          root: {
            //backgroundColor: '#213168',
            //color: "white",
            //backgroundColor: "white",
            //borderRadius: "0"
          }
        },
        MuiAppBar: {
          colorSecondary: {
            color: "black",
            backgroundColor: "white",
          }
        },
        Component: {
          title: {
            color: "white"
          }
        },
        RaAppBar: {
          root: {
            color: "white !important",
          },
          toolbar: {
            backgroundColor: '#042B6D',
            color: "white",
            width: "100%"
          },
          menuButton: {
            backgroundColor: '#042B6D',
            color : "white",
            height: '55px',
            marginLeft: '0'
          }
        },
        RaConfirm: {

        },
        MuiPaper: {
          root: {
            borderTopRightRadius: '0px !important',
            borderTopLeftRadius: '0px !important',
          }
        },
        MuiButton: { // override the styles of all instances of this component
            root: { // Name of the rule
                color: 'blue', // Some CSS
                minWidth: '32px'
            },
            textSizeSmall: {
                paddingTop: '0',
                paddingLeft: '0',
                paddingRight: '4px',
                paddingBottom: '0'
            }
        },
        MuiFormLabel: {
          root: { // Name of the rule
              color: 'black !important', // Some CSS
          },
        },
        MuiTypography: {
          root: { // Name of the rule
              //color: 'black !important', // Some CSS
          },
        },
        MuiListItemIcon: {
          root: { // Name of the rule
              //color: 'white', // Some CSS
          },
        },
        MuiTableSortLabel: {
          root: { // Name of the rule
              color: '#042B6D  !important', // Some CSS
          },
        },
        RaMenuItemLink: {
          root: {
            color: 'white',
          },
          active: {
            color: '#042B6D !important',
            backgroundColor: '#EEEEEE !important'
          },
          MuiIconButton: {

          }
        },
        MuiMenuItem: {
          root: { // Name of the rule
              fontSize: '14px',
              fontFamily: [
                  "Avenir Next Bold",
                  '-apple-system',
                  'BlinkMacSystemFont',
                  "Helvetica Neue",
                  'Arial',
                  'sans-serif',
              ].join(','),
          },
        },
        MuiSnackbarContent: {
          root: { // Name of the rule
              color: 'white', // Some CSS
          },
        },
        MuiTableCell: {
          root: { // Name of the rule
              //  verticalAlign: 'top !important' Some CSS
          },
        },
        MuiIconButton: {
          colorInherit: {
            color: "black",
            backgroundColor: "white",
            borderRadius: "0"
          },
          '&:hover': {
            color: '#0382ff !important',
            backgroundColor: '#0382ff !important'
          },
        },
        SimpleFormIterator: {
          line: {
            root: {
                float: 'left !important',
                width: '20% !important',
                color: '#004268 !important', // Some CSS
            }
          },
        },
        RaSidebar: {
          drawerPaper: {
            backgroundColor: '#042B6D',
          },
          fixed: {
            width: '100%',
            height: '100%'
          },
          root: {
            height: ''
          }
        },
        RaAutocompleteSuggestionList: {
          suggestionsContainer: {
            zIndex: 2000
          }
        },
        MuiDialog: {
          paperWidthMd: {
            maxWidth: '1500px'
          }
        },
        MuiPopover: {
          paper: {
            //marginTop: '40px !important'
          }
        },
        MUIDataTableBody: {
          emptyTitle: {
            textAlign: 'left'
          }
        },
        RaLayout: {
          content: {
            padding: '10px 20px !important'
          }
        },
        MuiTablePagination: {
          toolbar: {
            width: '85vw'
          }
        },
        RaListToolbar: {
          toolbar: {
            width: '85vw'
          }
        },
        RaList: {
          main: {
            overflowX: 'scroll'
          },
          bulkActionsDisplayed: {
            overflowX: 'auto',
            marginTop: 0
          }
        },
        RaBulkActionsToolbar: {
          toolbar: {
            backgroundColor: 'white'
          }
        }
    },
});

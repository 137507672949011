import React from 'react';
import simpleRestProvider from 'ra-data-simple-rest';
import { fetchUtils } from 'react-admin'; //  GET_ONE, UPDATE,
import { toast } from 'react-toastify';

// A function decorating a dataProvider for handling user profiles
const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('tap-token');
    const username = localStorage.getItem('username');
    const userid = localStorage.getItem('userid');
    //console.log("username ", username);
    options.headers.set('Authorization', `${token}`);
    options.headers.set('username', `${username}`);
    options.headers.set('userid', `${userid}`);
    if (url.indexOf("upload") >= 0)
      options.headers.set('Content-Type', `multipart/form-data;charset=UTF-8`);
    return fetchUtils.fetchJson(url, options);
}

const dataProvider = simpleRestProvider(process.env.REACT_APP_API_URL, httpClient)

const myDataProvider = {
    ...dataProvider,
    getList: (resource, params) => {
      //console.log("getList params " + resource , params)
      if (params && params.pagination && params.pagination.perPage) {
        localStorage.setItem(resource + '/perPage', params.pagination.perPage);
      }
      return dataProvider.getList(resource, params).catch(({ status, statusText, headers, body }) => {        
        if (body && body.error && body.status === 401) {          
          return dataProvider.getMany('users/logout', {
              id: 0
            }).then((test) => {
                localStorage.clear()
            }).finally(() => {
              window.history.pushState({}, document.title, "/#/");
              window.location = "/#"
              window.location.reload()
            })
        } else {          
          toast.dismiss()
          var errorMessage = <div><div className="toastTitle">Error</div><div className="toastMainMessage">{body && body.error && body.error.message ? body.error.message : "Internal Server Error"}</div></div>
          toast.error(errorMessage, {
            position: toast.POSITION.BOTTOM_CENTER,
            closeButton: true,
            autoClose: 5000
          });
        }
      });
    },
    update: (resource, params) => {
      // fallback to the default implementation
      return dataProvider.update(resource, params).catch(({ status, statusText, headers, body }) => {        
        toast.dismiss()
        var errorMessage = <div><div className="toastTitle">Error</div><div className="toastMainMessage">{body && body.error && body.error.message ? body.error.message : "Internal Server Error"}</div></div>
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_CENTER,
          closeButton: true,
          autoClose: 5000
        });
      });
    },
    create: (resource, params) => {
      return dataProvider.create(resource, params).catch(({ status, statusText, headers, body }) => {        
        toast.dismiss()
        var errorMessage = <div><div className="toastTitle">Error</div><div className="toastMainMessage">{body && body.error && body.error.message ? body.error.message : "Internal Server Error"}</div></div>
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_CENTER,
          closeButton: true,
          autoClose: 5000
        });
      });
    },
    delete: (resource, params) => {
      console.log("Inside delete params ", params);
      return fetch(process.env.REACT_APP_API_URL + "/" + resource + "/" + params.id, {
            method: "DELETE",
            headers: new Headers({'Authorization': localStorage.getItem('tap-token'), 'Content-Type' : 'application/json'}),
            body: JSON.stringify(params.previousData)})
        .then(response => response.json())
        .then(responseData => {
          console.log("Inside delete responseData ", responseData)
            return { data : responseData }
        }).catch(({ status, statusText, headers, body }) => {
          console.log("error deleting User ", body);
          toast.dismiss()
          var errorMessage = <div><div className="toastTitle">Error</div><div className="toastMainMessage">{body && body.message ? body.message : "Internal Server Error"}</div></div>
          toast.error(errorMessage, {
            position: toast.POSITION.BOTTOM_CENTER,
            closeButton: true,
            autoClose: 5000
          });
        });
    },
}
export default myDataProvider;

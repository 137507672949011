import React  from 'react';
import { Show, SimpleShowLayout, TextField, DateField } from 'react-admin';
import Breadcrumbs from '../components/Breadcrumbs'

class SPAUsersRoleShowSection extends React.Component {
  render() {
    return <section className='section'>
    <Breadcrumbs />
    <Show {...this.props}  actions={null}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="roleName" />
            <TextField source="roleDesc" />
            <DateField source="createdAt" />
            <DateField source="modifiedAt" />
        </SimpleShowLayout>
    </Show>
    </section>
  }
}
export const SPAUsersRoleShow = props => (
  <SPAUsersRoleShowSection {...props} />
);

import * as React from 'react';
import { Fragment, useState } from 'react';
import { FileCopy } from '@material-ui/icons';
import {
    Button,
    Confirm,
    useRefresh,
    useNotify,
    useUnselectAll,
} from 'react-admin';

const BulkCopyButton = ({ selectedIds }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();

    const updateMany = function() {
        setLoading(true)
        const token = localStorage.getItem('tap-token');
        const requestOptions = {
           method: 'POST',
           headers: { 'Content-Type': 'application/json', Authorization: token},
           body: JSON.stringify(selectedIds)
        };
        fetch(process.env.REACT_APP_API_URL + '/licensing/copyBulk', requestOptions)
        .then(responseData => {
          refresh();
          notify('Licensing Job(s) Copied');
          unselectAll('licensing/list');
          setLoading(false)
        }).catch(error  => {
          console.log("error ", error)
          notify('Error: licensing jobs not copied', { type: 'warning' })
          setLoading(false)
        });
    };
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        updateMany();
        setOpen(false);
    };

    return (
        <Fragment>
            <Button label="Copy" onClick={handleClick}><FileCopy/></Button>
            <Confirm
                isOpen={open}
                loading={loading}
                title={"Copy Licensing Job" + (selectedIds.toString().indexOf(',') > 0 ? "s" : "")}
                content={"Are you sure you want to copy " + (selectedIds.toString().indexOf(',') > 0 ? "the selected licensing jobs?" : "this licensing job?")}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
}

export default BulkCopyButton;
